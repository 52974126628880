.createHorario {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 20px;
    position: relative;
    min-height: 100vh; /* Ocupa toda a altura da viewport */
    padding-bottom: 80px; /* Espaço para o botão fixo */
}

.createHorario_top {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;
}

.createHorario_top select {
    width: 95%;
    margin-top: 5px;
    padding: 10px;
    font-size: 16px;
    border-radius: 3px;
    border: 1px solid #ccc;
    transition: 1s;
    color: gray;
}

.createHorario_top select:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

.createHorario_bottom {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;
    border-left: 2px solid #ec3237 ;
    margin-top: 30px;
    padding-left: 20px;
    position: relative;
}

.createHorario_bottom input {
    width: 95%;
    margin-top: 5px;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    transition: border-bottom-color 0.3s;
    font-size: 16px;
}

.createHorario_bottom input:focus {
    outline: none;
    border-bottom-color: #ec3237;
    /* Cor quando em foco */
}

.createHorario_bottom select {
    width: 100%;
    margin-top: 5px;
    padding: 10px;
    font-size: 16px;
    border-radius: 3px;
    border: 1px solid #ccc;
    transition: 1s;
    color: gray;
}

.createHorario_bottom select:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

.createHorario_bottom h3 {
    color: rgb(85, 74, 74);
}

.createHorario_bottom .deleteDayWeek {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 20px;
    padding-right: 10px;
}

.createHorario_bottom .deleteDayWeek button{
    background-color: rgb(228, 55, 55);
    border-radius: 5px;
    color: white;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s;
}

.createHorario_bottom .deleteDayWeek button:hover {
    background-color: rgb(187, 38, 38);
}

.materiaContainer {
    width: 100%;
}

.materiaContainer_item {
    width: 90%;
    border: 1px solid rgb(214, 214, 214);
    border-radius: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    margin-top: 10px;
}

.materiaContainer_item_delete {
    display: flex;
    justify-content: end;
}

.materiaContainer_item_delete button{
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: gray;
    font-size: 20px
}

.action_buttons {
    width: 80%;
    background-color: rgb(255, 255, 255);
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px 20px;
}

.action_buttons button{
    border: 1px solid gray;
    color: rgb(87, 87, 87);
    font-weight: 600;
    background-color: white;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    margin-right: 8px;
    transition: 0.8s;
}

.action_buttons button:hover{
    opacity: 0.8; 
    border: 1px solid rgba(128, 128, 128, 0.616);
}

.action_buttons .send {
    background-color: #ec3237;
    color: white;
}

.addMateria {
    background-color: #ffffff;
    font-weight: 600;
    color: rgb(116, 108, 108);
    width: 93.5%;
    border: 1px solid #928d8d;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 5px;
}

@media(max-width:700px) {
    .createHorario_bottom {
        padding-bottom: 70px;
    }

    .action_buttons {
        width: 90%;
        background-color: rgb(255, 255, 255);
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        gap: 10px;
    }
    
    .action_buttons button{
        border: 1px solid gray;
        color: rgb(87, 87, 87);
        font-weight: 600;
        background-color: white;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;
        margin-right: 8px;
        width: 100%;
        height: 40px;
    }
}