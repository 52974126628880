.all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.home {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-y: auto;
}

.returnPainelArrow {
    padding-top: 20px;
    width: 96%;
}

.returnPainelArrow a {
    width: 70px;
    display: flex;
    justify-content: start;
    align-items: center;
    text-decoration: none;
    color: #ec3237;
    font-size: 12px;
    transition: 0.5s;
}

.returnPainelArrow a:hover {
    color: #c4292e;
}

.topAreaInfo {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 20px;
}

.topAreaInfo .start {
    height: 100%;
}

.topAreaInfo .start h1 {
    font-size: 18px;
    margin: 0px;
    padding: 0px;
}

.topAreaInfo .start p {
    padding-right: 20px;
    font-size: 11px;
    margin: 0px;
    padding: 0px;
    color: gray;
}

@media (max-width:700px) {
    .home {
        width: 100%;
        height: 100%;
    }
}

.form {
    border-top: solid 1px gray;
    width: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
}

.form p {
    font-size: 16px;
    color: gray;
    font-weight: 600;
}


.botoesDeControle {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    padding-left: 10px;
    gap: 10px;
    padding-top: 10px;
}

.botoesDeControle .undoButton {
    background-color: rgb(209, 209, 209);
    transition: 1s;
    border: solid 1px rgb(104, 104, 104);
}

.botoesDeControle .undoButton:hover {
    border: solid 1px rgb(151, 151, 151);
    padding-bottom: 0px;
}

.botoesDeControle button{
    width: 90%;
    height: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: solid 1px rgb(151, 151, 151);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    border-radius: 15px;
    padding: 8px 15px 8px 15px;
    background-color: rgb(252, 238, 111);
    color: white;
    cursor: pointer;
    transition: 1s;
}

.botoesDeControle button:hover {
    background-color: rgb(255, 245, 150);
}

.botoesDeControle .add{
    background-color: #ec3237;
    color: white;
}

/* INFORMAÇÔES BASICAS */
.BasicInfoForm {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;
}

.BasicInfoForm input {
    width: 90%;
    margin-top: 5px;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    transition: border-bottom-color 0.3s;
    font-size: 16px;
}

.BasicInfoForm input:focus {
    outline: none;
    border-bottom-color: #ec3237; /* Cor quando em foco */
}

.BasicInfoForm select {
    width: 93%;
    margin-top: 5px;
    padding: 10px;
    font-size: 16px;
    border-radius: 3px;
    border: 1px solid #ccc;
    transition: 1s;
    color: gray;
}

.BasicInfoForm select:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

/* QUESTÕES */
.questoesAll {
    width: 100%;
    max-height: 400px;
    min-height: 400px;
    overflow-y: auto;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 20px;
}

.questao {
    width: 90%;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-bottom: 20px;
}

.questao .TopArea {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.questao .TopArea button{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #7a0509;
    background-color: #ff8387ee;
    font-size: 15px;
    transition: 1s;
    cursor: pointer;
}

.questao .TopArea button:hover{
    color: #7a0509b7;
    background-color: #ff83879d;
}

.questionelement {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.questionelement label{
    color: #7a0509b7;
    font-weight: 800;
    font-size: 15px;
}

.questionelement input {
    border: 1px solid rgb(206, 206, 206);
    color: gray;
}

.questionelement input:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

.respostas {
    width: 95%;
    padding-top: 10px;
    padding-left: 10px;
}

.respostas label {
    color: #7a0509b7;
    font-weight: 800;
    font-size: 13px;
}

.respostas input {
    width: 50%;
    border: 1px solid rgb(206, 206, 206);
    color: gray;
}

.respostas input:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

.itemCorreto {
    width: 95%;
    display: flex;
    flex-direction: column;
    padding-top:10px;
}
.itemCorreto label{
    color: #7a0509b7;
    font-weight: 800;
    font-size: 15px;
}

.itemCorreto select {
    border: 1px solid rgb(206, 206, 206);
    color: gray;
}

.itemCorreto select:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

.reactSelect {
    width: 80%;
}

.editorWrapper {
    width: 100%;
    max-width: 100%; /* Garante que não ultrapasse 100% da largura do contêiner pai */
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background: #fff;
    box-sizing: border-box; /* Inclui padding e borda na largura total */
    overflow: hidden; /* Impede que o conteúdo transborde */
}
.editor {
    min-height: 200px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #fff;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 10px;
}

.toolbar {
    border: 1px solid #ddd;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    background: #f5f5f5;
}

.BasicInfoForm .error {
    color: rgb(224, 0, 0);
    font-weight: 500;
    font-size: 14px;
    margin-top: 0px;
}

.materiaItemHeader {
    width: 90%;
    display: flex;
    justify-content:space-between;
    align-items: end;
    padding-bottom: 5px;
}

.materiaItemHeader h2 {
    margin-bottom: 0px;
}

.materiaItemHeader .removeButton {
    border: solid 1px rgba(255, 0, 0, 0.288);
    background-color: #f14248;
    color: white;
    border-radius: 5px;
    padding: 5px 6px 5px 6px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 1s;
}

.materiaItemHeader .removeButton:hover {
    background-color: #aa1a1e;
}

@media (max-width:700px) {
    .form {
        width: 90%;
        padding-bottom: 30px;
    }
    .reactSelect {
        width: 90%;
    }
}