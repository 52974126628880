.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.form label {
    display: block;
    font-weight: bold;
    margin-top: 10px;
    color: #555;
}

.form input, .form textarea, .form select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.materiaRow {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.materiaRow input {
    flex: 1;
}

.addButton, .removeButton, .submitButton {
    margin-top: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.addButton {
    background-color: #28a745;
    color: white;
}

.removeButton {
    background-color: #dc3545;
    color: white;
    margin-left: 10px;
}

.submitButton {
    background-color: #007bff;
    color: white;
    width: 100%;
}

.message {
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
    color: #007bff;
}
