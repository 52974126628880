.all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.home {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start; /* Ajustado para centralizar o conteúdo */
    align-items: center; /* Ajustado para centralizar o conteúdo */
    overflow-y: auto;
}

@media (max-width: 700px) {
    .home {
        width: 100%;
        height: 100%;
    }
}

.grid {
    display: flex;
    justify-content: center;
    width: 100%; /* Garantir que o contêiner ocupe toda a largura disponível */
}

.sliderHome {
    width: 90%; /* Ajustado para ocupar a largura total do contêiner */
    max-width: 1200px; /* Ajustar conforme necessário */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.sliderContainer {
    position: relative;
    height: 600px; /* Ajustar conforme necessário */
    width: 100%; /* Garantir que o contêiner ocupe toda a largura disponível */
    overflow: hidden; /* Para evitar que conteúdos excedam os limites do contêiner */
}

.overlayTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.overlayDescription {
    font-size: 1rem;
    line-height: 1.5;
}

.sliderContainer {
    position: relative;
    width: 100%; /* Ocupa toda a largura disponível */
    aspect-ratio: 16 / 9; /* Define a proporção 16:9 */
    overflow: hidden; /* Esconde qualquer conteúdo excedente */
    border-radius: 20px;
}

.sliderContainer img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Garante que a imagem seja ajustada corretamente */
    object-position: center; /* Centraliza a imagem */
    border-radius: 20px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.24); /* Fundo semitransparente */
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    padding: 20px;
    text-align: center;
    border-radius: 20px;
}
.overlay .info { 
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 40px;
    margin-right: 30px;
}
.overlay .info h3{ 
    padding: 5px;
    margin: 0px;
}
.overlay .info p{ 
    padding: 5px;
    margin: 0px;
    text-align: start;
}

.overlay .info .topico{ 
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 20px;
    margin-right: 20px;
    background-color: white;
    color: rgb(214, 21, 21);
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    padding: 4px 16px ;
    margin: 0px;
}

@media (max-width:1000px) {
    .sliderContainer {
        height: 500px;
    }
}

@media (max-width:700px) {
    .sliderContainer {
        height: 250px;
    }
}