.all {
    background-color: #f5f5f5;
    height: 100%;
    min-height: 91.9vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 30px;
}

.all .content {
    background-color: rgb(255, 255, 255);
    width: 60%;
    height: 90%;
    border-radius: 10px;
    padding: 10px;
    padding-bottom: 30px;
}

.all .content .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
}

.all .content .logo img {
    width: 100px;
    height: 100px;
    background-color: transparent;
    border: 1px solid transparent;
    /* Adicione uma borda para visualizar melhor */
}

.all .content form {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 10px;
}

.all .content form button{
    width: 90%;
    height: 50px;
    margin-top: 20px;
    border-radius: 4px;
    border: 1px solid rgb(221, 221, 221);
    background-color: #ec3237;
    color: #ffde59;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
    transition: 1s;
}

.all .content form button:hover{
    background-color: #c42429;
}

.all .content form .group {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 10px;
    width: 90%;
}

.all .content form .group label{
    font-weight: 600;
    color: #5c5c5c;
}

.all .content form .group input {
    width:98%;
    margin-top: 5px;
    padding: 5px;
    padding-top: 0px;
    border: none;
    border-bottom: 1px solid #ccc;
    transition: border-bottom-color 0.3s;
    font-size: 16px;
}

.all .content form .group input:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

.TopForm {
    width: 90%;
    color: #c42429;
}
.TopForm p{
    width: 90%;
    font-weight: 600;
    color: #6d6d6d;
}
.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Certifique-se de que o popup esteja acima de outros elementos */
}

.popupContent {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    position: relative;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}


@media (max-width:1000px) {
    .all .content {
        width: 80%;
    }
}

@media (max-width:700px) {
    .all .content {
        width: 90%;
    }
}