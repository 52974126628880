

.group {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding-bottom: 10px;
}

.group label{
    font-weight: 600;
    color: #3f3e3e;
}

.group input {
    width: 90%;
    margin-top: 5px;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    transition: border-bottom-color 0.3s;
    font-size: 16px;
}

.group input:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

.content h1 {
    color: rgb(214, 36, 36);
    font-size: 24px;
}

.content h2 {
    color: gray;
    font-size: 17px;
}

.content  .form{
    overflow-y: auto;
    max-height: 360px;
    gap: 10px;
    width: 100%;
    padding-bottom: 20px;
}

.actions {
    height: 50px;
    border-top: 1px solid gray;
    display: flex;
    align-items: center;
    gap: 10px;
}

.actions button{
    border: 1px solid gray;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: white;
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    font-weight: 500;
}

.groupCampo {
    width: 81%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border: 1px solid gray;
    min-height: 200px;
    border-radius: 10px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.groupCampo label{
    font-weight: 600;
    color: #3f3e3e;
}

.groupCampo input {
    width: 90%;
    margin-top: 5px;
    padding: 5px;
    padding-top: 0px;
    border: none;
    border-bottom: 1px solid #ccc;
    transition: border-bottom-color 0.3s;
    font-size: 16px;
}

.groupCampo input:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

.actions {
    height: 50px;
    border-top: 1px solid gray;
    display: flex;
    align-items: center;
    gap: 10px;
}

.actions button{
    border: 1px solid gray;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: white;
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    font-weight: 500;
}

@media (max-width:700px) {
    .group input {
        width: 80%;
    }
    .content .form{
        max-height: 600px;
    }
}