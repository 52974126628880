.all {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}

.home {
  width: 95%;
  height: 95%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

/*  */
.topAreaInfo {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid gray;
}

.topAreaInfo .start {
  height: 100%;
  padding-left: 20px;
}

.topAreaInfo .start h1 {
  font-size: 18px;
  margin: 0px;
  padding: 0px;
}

.topAreaInfo .start p {
  padding-right: 20px;
  font-size: 11px;
  margin: 0px;
  padding: 0px;
  color: gray;
}

.topAreaInfo .end {
  height: 100%;
  width: 500px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  padding-right: 20px;
}

.topAreaInfo .end a {
  border-radius: 4px;
  color: white;
  font-size: 11px;
  font-weight: 700;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 8px;
  padding-left: 8px;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  background-color: #ec3237;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  transition: 1s;
}

.topAreaInfo .end a {
  text-decoration: none;
  background-color: #c4292e;
}

.marginStart {
  width: 100%;
  overflow-x: auto;
}

.bottomAreaInfo {
  width: 100%;
  overflow-y: auto;
}

.allBottomAreaInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.bottomAreaInfo .start {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 20px;
  padding-left: 3px;
  padding-right: 0px;
  white-space: nowrap;
}

.bottomAreaInfo .start div {
}

.bottomAreaInfo .start div p {
  padding-left: 33%;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  margin-bottom: 5px;
  color: #c72a2f;
}

.bottomAreaInfo .start select {
  border-radius: 5px;
  width: 150px;
  padding: 7px 15px 7px 15px;
  border: solid 1px rgba(226, 226, 226, 0.863);
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  color: gray;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  background-color: white;
}

.bottomAreaInfo .start select:focus {
  outline: none;
  /* Garante que não haja borda ao focar */
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  /* Mantém a sombra ao focar */
}

.planeContainer {
  background-color: transparent;
}

.planeContainer .plane .headPlane {
  border-left: 2px solid rgb(184, 73, 73);
  padding-left: 20px;
}

.planeContainer .plane .headPlane h2 {
  color: rgb(122, 18, 18);
}

.planeContainer .plane .headPlane p {
  min-height: 80px;
  color: gray;
}

.planosList {
  order: 2;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  border-radius: 5px;
  gap: 10px;
  padding-bottom: 10px;
}

.planosList .plane {
  width: 100%;
  border: solid 1px rgba(233, 233, 233, 0.753);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}

.planosList .plane .headPlane {
  width: 100%;
  border: none;
}

.planosList .plane .headPlane {
  width: 90%;
  color: rgb(102, 102, 102);
}

.planosList .plane .headPlane h2 {
  color: gray;
}

.planosList .plane .headPlane p {
  min-height: 30px;
  padding-left: 5px;
}

.endItems {
  width: 95%;
}

.headPlane h3 {
  color: #c4292e;
}
/* Container do plano */
.plane {
  position: relative;
  /* Outros estilos existentes... */
}

/* Container dos botões de ação */
.actionButtons {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  gap: 8px;
}

/* Estilização dos botões de ação */
.actionButtons button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.4em;
  color: #a8a8a8;
  transition: color 0.3s, background-color 0.3s;
  padding: 5px;
  border-radius: 50%;
}

.actionButtons button:hover {
  color: #c4292e;
  background-color: rgba(0, 0, 0, 0.05);
}

.editButton,
.deleteButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: #a8a8a8;
  transition: 1s;
}

.editButton:hover,
.deleteButton:hover {
  color: #505050;
}

/* Outros estilos */

/* Adicione esses estilos ao seu arquivo PlanosAdminPage.module.css */

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup .title {
  text-align: start;
}

.popup .title h2 {
  font-size: 18px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.popup .title p {
  font-size: 13px;
  margin-top: 0px;
  padding-top: 0px;
  color: gray;
  font-weight: 600;
}

.popupActions {
  display: flex;
  justify-content: right;
  margin-top: 20px;
  gap: 8px;
}

.popupActions button {
  padding: 5px 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.popupActions button:first-child {
  color: black;
  background-color: #f0f0f0; /* Cor para o botão de cancelamento */
}

.popupActions button:last-child {
  background-color: #d9534f; /* Cor para o botão de confirmação */
  color: white;
}

.notFound {
  width: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.notFound p {
  font-size: 15px;
  font-weight: 600;
  color: #a8a8a8;
}
.notFound img {
  width: 200px;
}

@media (max-width: 1050px) {
  .bottomAreaInfo .start {
    display: flex;
    justify-content: start;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 20px;
    padding-left: 5px;
    white-space: nowrap;
  }
}

@media (max-width: 700px) {
  .home {
    width: 100%;
    height: 100%;
  }

  .topAreaInfo {
    flex-direction: column;
    align-items: start;
    height: 70px;
  }

  .topAreaInfo .end {
    justify-content: start;
    padding-left: 18px;
    padding-top: 10px;
    height: 170px;
    width: 90%;
  }
}
