.all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.home {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-y: auto;
}

.user {
    flex: 4;
    padding: 20px;
}

.userTitleContainer {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.userAddButton {
    width: 80px;
    border: none;
    padding: 5px;
    background-color: teal;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}

.userContainer {
    display: flex;
    margin-top: 10px;
    margin-bottom: 40px;
    width: 95%;
}

.userShow {
    flex: 1;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.userUpdate {
    flex: 2;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    margin-left: 20px;
}

.userShowTop {
    display: flex;
    align-items: center;
}

.userShowImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.userShowTopTitle {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.userShowUsername {
    font-weight: 600;
}

.userShowUserTitle {
    font-weight: 300;
}

.userShowBottom {
    margin-top: 20px;
}

.userShowTitle {
    font-size: 14px;
    font-weight: 600;
    color: rgb(175, 170, 170);
}

.userShowInfo {
    display: flex;
    align-items: center;
    margin: 20px 0px;
    color: #444;
}

.userShowIcon {
    font-size: 16px !important;
}

.userShowInfoTitle {
    margin-left: 10px;
}

.userUpdateTitle {
    font-size: 24px;
    font-weight: 600;
}

.userUpdateForm {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.userUpdateItem {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.userUpdateItem>label {
    margin-bottom: 5px;
    font-size: 14px;
}

.userUpdateInput {
    border: none;
    width: 250px;
    height: 30px;
    border-bottom: 1px solid gray;
}

.userUpdateRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.userUpdateUpload {
    display: flex;
    align-items: center;
}

.userUpdateImg {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 20px;
}

.userUpdateIcon {
    cursor: pointer;
}

.userUpdateButton {
    border-radius: 5px;
    border: none;
    padding: 5px;
    cursor: pointer;
    background-color: #ffde59;
    color: white;
    font-weight: 600;
    transition: 1s;
}

.userUpdateButton:hover {
    border-radius: 5px;
    border: none;
    padding: 5px;
    cursor: pointer;
    color: white;
    background-color: rgb(255, 212, 39);
    font-weight: 600;
}

.passwordUpdateSection {
    width: 92%;
    flex: 2;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    margin-left: 20px;
}
.passwordUpdateItem {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.passwordUpdateItem>label {
    margin-bottom: 5px;
    font-size: 14px;
}

.passwordInput {
    border: none;
    width: 250px;
    height: 30px;
    border-bottom: 1px solid gray;
}

.PasswordUpdateTitle {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 20px;
}

.passwordUpdateButton {
    width: 255px;
    border-radius: 5px;
    border: none;
    padding: 5px;
    cursor: pointer;
    background-color: #ffde59;
    color: white;
    font-weight: 600;
    transition: 1s;
    margin-top: 10px;
    margin-bottom: 10px;
}

.passwordUpdateButton:hover {
    border-radius: 5px;
    border: none;
    padding: 5px;
    cursor: pointer;
    color: white;
    background-color: rgb(255, 212, 39);
    font-weight: 600;
}

@media (max-width:900px) {
    .userContainer {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 40px;
        width: 95%;
        gap: 10px;
    }  
    .userUpdate {
        margin-left: 0px;
    }

    .passwordUpdateSection {
        width: 90%;
    }
}

@media (max-width:700px) {
    .home {
        width: 100%;
        height: 100%;
        border-radius: none;
    }
    .userUpdateForm {
        flex-direction: column;
    }
    .userUpdateRight {
        gap: 20px;
        padding-top: 10px;
    }
    .passwordUpdateButton {
        width: 95%;
        border-radius: 5px;
        border: none;
        padding: 5px;
        cursor: pointer;
        background-color: #ffde59;
        color: white;
        font-weight: 600;
        transition: 1s;
    }
}

/* @media (max-width:700px) {
    .home {
        width: 100%;
        height: 100%;
    }
} */