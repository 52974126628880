/* CalendarioViewComponent.module.css */

@keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .slideIn {
    animation: slideInFromTop 0.5s ease-out forwards;
  }
.allScreen {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(95, 95, 95, 0.644);
    top: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    background-color: white;
    width: 80%;
    height: 90%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
}

.closeButton {
    width: 98%;
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 12px;
    color: rgb(95, 95, 95);
}

.closeButton .title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.closeButton .title .tit {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: auto;
}

.closeButton .title .tit h2{
    color: rgb(65, 61, 61);
    font-size: 17px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items:end;
    gap: 5px;
}

.closeButton .title .tit p{
    color: gray;
    font-size: 11px;
    margin: 0px;
}

.closeButton .title div {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    color: transparent;
}

.closeButton button {
    border: none;
    background-color: transparent;
    font-size: 25px;
    cursor: pointer;
    color: rgb(95, 95, 95);
    padding-right: 15px;
    transition: 1s;
}

.closeButton button:hover {
    color: brown;
}

.content .conteudo {
    width: 100%;
    height: 90%;
    border-top: 1px solid gray;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
}

.content .conteudo .desc {
    height: 90%;
    width: 90%;
    overflow-y: auto;
}

.content .conteudo .actions {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    background-color: #ec3237;
}

.content .conteudo .actions button{
    height: 100%;
    width: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
    border: none;
    background-color: transparent;
    transition: 1s;
    font-family: "Quicksand", sans-serif;
    gap: 5px;
}

.content .conteudo .actions button:hover{
    height: 100%;
    width: 50%;
    background-color: #ffffff50;
}

.content .conteudo .actions .create {
    border-right: 1px solid white;
}

.content .conteudo .actions .edit {
    border-left: 1px solid white;
}

@media(max-width:700px) {
    .allScreen {}

    .content {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }
}