.chart {
    padding: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    width: 90%;
}

.chartTitle {
    margin-bottom: 20px;
}

.ResponsiveContainer {
    width: 90%;
}

