.all {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.rightHeader {
    height: 100vh;
    width: 200px;
    background-color: #ec3237;
    display: flex;
    flex-direction: column;
    transition: 1s;
    transition: width 0.5s ease, transform 0.5s ease;
    transform: translateX(0);
    position: relative;
}

.rightHeader.open {
    width: 200px;
    /* Largura total do menu */
    transform: translateX(0);
    /* Posição final do menu aberto */
}

.rightHeader .logo {
    position: relative;
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: start;
    align-items: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 15px;
    justify-content: start;
    align-items: center;
    padding-left: 20px;
}

.rightHeader .logo img {
    z-index: 0;
    width: 90px;
    height: 90px;
}

.rightHeader .logo .menuButtom {
    position: absolute;
    top: 35px;
    right: 11%;
    width: 40px;
    height: 40px;
    background-color: #ec3237;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    transform: translate(50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
    z-index: 1;
    cursor: pointer;
    transition: 1s;
}

.rightHeader .logo .menuButtom:hover {
    background-color: #ca2a30;
}

.rightHeader .itemsContent {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}

.rightHeader .itemsContent::-webkit-scrollbar {
    width: 12px;
}

.rightHeader .itemsContent::-webkit-scrollbar-thumb {
    background-color: #c22228ce;
    border-radius: 0px;
}

.rightHeader .itemsContent .itemElement {
    width: 100%;
    max-width: auto;
    display: flex;
    justify-content: start;
    align-items: center;
    border-bottom: solid 1px #cc2228ce;
    cursor: pointer;
    transition: 1s;
    padding-top: 0px;
    padding-bottom: 0px;
    color: white;
    gap: 7px;
    padding: 15px;
    padding-left: 30px;
}

.rightHeader .itemsContent .itemElement:hover {
    background-color: #b42025ce;
}

.rightHeader .itemsContent .itemElement .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin: 0;
}

.rightHeader .itemsContent .itemElement .text {
    font-size: 12px;
    margin: 0;
}

.screen {
    width: 90%;
}

.screen header {
    width: 100%;
    height: 70px;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #c72a2f;
}

.screen header .rightSide {
    width: 200px;
}

.screen header .rightSide .logo {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.screen header .rightSide .logo img {
    height: 80%;
}

.screen header .leftSide {
    width: 300px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.screen header .leftSide .profile {
    height: 90%;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    gap: 10px;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
}

.screen header .leftSide .profile .profileMenu {
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 75px;
    width: 150px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    gap: 5px;
    display: flex;
    flex-direction: column;
    padding: 2px;
    z-index: 1;
}

.screen header .leftSide .profile a {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    color: black;
    transition: 0.5s;
    padding: 5px;
}

.screen header .leftSide .profile a:hover {
    color: #ec3237;
    border-radius: 2px;
    background-color: rgb(240, 240, 240);
}

.screen header .leftSide .profile .arrowProfile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.screen header .leftSide .profile img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.menuButtomRightHeader {
    background-color: #ec3237;
    color: #FFF;
    position: fixed;
    /* Alterado para 'fixed' para que o botão flutue pela tela */
    bottom: 70%;
    /* Defina a posição inicial do botão na parte inferior da tela */
    right: 95.7%;
    /* Defina a posição inicial do botão à direita da tela */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 18px;
    animation: floating 2s infinite ease-in-out;
    /* Adicionando a animação de flutuação */
    z-index: 1;
}

@keyframes floating {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-5px);
        /* Define a altura máxima da flutuação */
    }

    100% {
        transform: translateY(0);
    }
}

.menuButtomRightHeader:hover {
    background-color: #c72a2f;
}

.screen .contentScrennFull {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 88.2vh;
}

.contentScrennDash {
    width: 100%;
    height: 100%;
}

@media (max-width:2100px) {

}


@media (max-width:1000px) {
    .menuButtomRightHeader {
        background-color: #ec3237;
        color: #FFF;
        position: fixed;
        /* Alterado para 'fixed' para que o botão flutue pela tela */
        bottom: 65%;
        /* Defina a posição inicial do botão na parte inferior da tela */
        right: 94%;
        /* Defina a posição inicial do botão à direita da tela */
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
        font-size: 18px;
        animation: floating 2s infinite ease-in-out;
        /* Adicionando a animação de flutuação */
    }

    .screen header .rightSide .logo {
        height: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        padding-left: 20px;
    }

}

@media (max-width:700px) {
    .rightHeader {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        transition: 1s;
        transition: width 0.5s ease, transform 0.5s ease;
        /* Adicionando transições */
        transform: translateX(0);
        /* Posição inicial quando o menu está aberto */
        position: absolute;
        /* Adicionei position relative */
        z-index: 1;
    }

    .rightHeader .itemsContent {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        height: auto;
        max-height: 100%;
    }

    .rightHeader .itemsContent .itemElement {
        width: 90%;
        display: flex;
        justify-content: start;
        align-items: center;
        border-bottom: solid 1px #cc2228ce;
        cursor: pointer;
        transition: 1s;
        padding-top: 0px;
        padding-bottom: 0px;
        color: white;
        gap: 7px;
        padding: 15px;
        padding-left: 50px;
    }

    .rightHeader.open {
        width: 100%;
    }

    .rightHeader .logo {
        width: 95%;
    }

    .screen {
        width: 100%;
    }

    .menuButtomRightHeader {
        background-color: #ec3237;
        color: #FFF;
        position: fixed;
        /* Alterado para 'fixed' para que o botão flutue pela tela */
        bottom: 0%;
        /* Defina a posição inicial do botão na parte inferior da tela */
        right: 0%;
        /* Defina a posição inicial do botão à direita da tela */
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 45px;
        border-radius: 0%;
        cursor: pointer;
        font-size: 20px;
        animation: none;
        /* Adicionando a animação de flutuação */
    }

    .screen header .rightSide .logo {
        padding-left: 10px;
    }

}