/* CalendarioPage.module.css */
.all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.home {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-y: auto;
}


/*  */
.topAreaInfo {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.topAreaInfo .start {
    height: 100%;
}

.topAreaInfo .start h1 {
    font-size: 18px;
    margin: 0px;
    padding: 0px;
}

.topAreaInfo .start p {
    padding-right: 20px;
    font-size: 11px;
    margin: 0px;
    padding: 0px;
    color: gray;
}

.topAreaInfo .end {
    height: 100%;
    width: 500px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
}

.topAreaInfo .end a {
    border-radius: 4px;
    color: white;
    font-size: 11px;
    font-weight: 700;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: #ec3237;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition: 1s;
}

.topAreaInfo .end a {
    text-decoration: none;
    background-color: #c4292e;
}


.bottomAreaItem {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    border-top: solid 1px gray;
    width: 100%;
    padding-bottom: 50px;
}

.eventDay {
    color: inherit;
    /* Isso permitirá que a cor seja herdada do estilo inline */
}

.calendar {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 20px;
}

.calendarWrapper {
    width: 95%;
}

.reactCalendar {
    width: 100% !important;
    border-radius: 10px;
    padding: 5px;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    border: solid 0.5px rgba(230, 230, 230, 0.89);
}

.categories {
    width: 95%;
    border-radius: 10px;
    border: solid 0.5px rgba(230, 230, 230, 0.89);
    padding-top: 5px;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.categories h2 {
    width: 90%;
    font-size: 17px;
    font-weight: 700;
    color: rgb(77, 77, 77);
}

.categories .item {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 85%;
    gap: 10px;
    border-bottom: solid 0.3px rgba(226, 226, 226, 0.5);
    padding-bottom: 15px;
    padding-top: 10px;
    cursor: pointer;
}

.categories .item .colorBoll {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.categories .item .nameCat {
    color: rgb(168, 168, 168);
    font-size: 14px;
    font-weight: 500;
}


@media (max-width: 700px) {
    .home {
        width: 100%;
        height: 100%;
    }

    .topAreaInfo {
        flex-direction: column;
        align-items: start;
        width: 95%;
        height: auto;
        gap: 10px;
    }

    .topAreaInfo .end {
        justify-content: start;
        width: 100%;
        justify-content: start;
    }
}