.all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.home {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: center; /* Ajustado para centralizar o conteúdo */
    align-items: center; /* Ajustado para centralizar o conteúdo */
    overflow-y: auto;
}

@media (max-width: 700px) {
    .home {
        width: 100%;
        height: 100%;
    }
}

.grid {
    display: flex;
    justify-content: center;
    width: 100%; /* Garantir que o contêiner ocupe toda a largura disponível */
}

.sliderHome {
    width: 90%; /* Ajustado para ocupar a largura total do contêiner */
    max-width: 1200px; /* Ajustar conforme necessário */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.sliderContainer {
    height: 400px; /* Ajustar conforme necessário */
    width: 100%; /* Garantir que o contêiner ocupe toda a largura disponível */
}

.sliderContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
}
