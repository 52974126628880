/* Animação para abrir a popup */
@keyframes slideDown {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Animação para fechar a popup */
@keyframes slideUp {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}

/* Estilos do contêiner pai */
.container {
    position: relative;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    min-height: 400px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

/* Estilo do popup de categoria */
.popup {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    overflow: auto;
    max-height: 90vh;
    animation: slideDown 0.5s ease-out forwards;
    position: absolute; /* Altera para absolute */
    top: 0; /* Começa do topo do contêiner */
    left: 0; /* Alinha ao lado esquerdo do contêiner */
    right: 0; /* Garante que ocupe toda a largura disponível */
    margin: 0 auto; /* Centraliza no contêiner */
    z-index: 1000;
    box-sizing: border-box;
    transition: opacity 0.3s ease, transform 0.3s ease;
    width: 100%;
    height: 100%;
}

/* Estilos das ações (botão de adicionar) */
.actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 95%;
    display: flex;
    justify-content: end;
    align-items: center;
    min-height: 50px;
    background-color: rgba(255, 0, 0, 0);
    z-index: 10;
    padding-bottom: 30px;
}

.openButton {
    padding: 10px;
    border-radius: 50%;
    border: 1px solid rgb(226, 226, 226);
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ca373c;
    color: white;
    transition: background-color 0.3s ease;
    font-size: 24px;
}

.openButton:hover {
    background-color: #b32c31;
}

/* Estilos para a lista de categorias */
.categoryList {
    margin-top: 20px;
    width: 100%;
    padding-bottom: 70px;
}

.categoryList ul {
    list-style-type: none;
    padding: 0;
}

.categoryItem {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
}

.categoryHeader {
    background-color: #f9f9f9;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.categoryHeader:hover {
    background-color: #e0e0e0;
}

.itemList {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* Estilos para o item */
.item {
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 15px;
    transition: box-shadow 0.3s ease;
}

.item:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.itemContent {
    display: flex;
    align-items: center;
}

.itemImage {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 15px;
    border: 1px solid #ddd;
}

.itemDetails {
    flex: 1;
}

.itemDetails h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.itemDetails p {
    margin: 5px 0;
    color: #666;
}

.itemPrice {
    font-weight: bold;
    color: #000;
    margin-top: 10px;
}

.noItems {
    padding: 10px;
    font-style: italic;
}

.action {
    background-color: rgb(177, 177, 177);
    color: white;
    border-radius: 10px;
    padding: 7px 10px 7px 10px;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.action .right{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.action .left {
    gap: 5px;
    display: flex;
}

.action .left button {
    padding: none;
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: transparent;
}



.itemDetailsActions {
    display: flex;
    justify-content: end;
    padding-right: 40px;
    align-items: center;
    height: 20%;
    width: 97%;
    gap: 10px;
}

.itemDetailsActions button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #7a0509;
    background-color: #ff8387ee;
    font-size: 15px;
    transition: 1s;
    cursor: pointer;
}

.itemDetailsActions button:hover {
    color: #7a0509b7;
    background-color: #ff83879d;
}

.itemDetailsActions .updateButton {
    background-color: #fff383ee;
    color: #665d07ee;
}

.itemDetailsActions .updateButton:hover {
    background-color: #dacf70ee;
}

/* Animações */
@keyframes slideDown {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}

/* Media Queries */
@media (max-width:700px) {
    .popup {
        padding: 20px;
        width: 100%;
        max-width: 400px;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}

@media (min-height: 500px) {
}

@media (min-height:600px) {
}

@media (min-height:700px) {
}

@media (min-height:800px) {
}