.all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.home {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-y: auto;
}

.homeWidgets {
    display: flex;
    margin: 20px;
}

.topAreaInfo {
    width: 95%;
    display: flex;
    flex-direction: column;
}

.topAreaInfo.noButtons {
    height: auto; /* Ajuste dinâmico */
    height: 80px;
}

.painel {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 10px;
}

.painel .start {
    height: 100%;
}

.painel .start h1 {
    font-size: 18px;
    margin: 0px;
    padding: 0px;
}

.painel .start p {
    padding-right: 20px;
    font-size: 11px;
    margin: 0px;
    padding: 0px;
    color: gray;
}

.painel .end {
    height: 100%;
    width: 500px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
}

.painel .end input[type="date"] {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 3px;
    padding-left: 3px;
    font-size: 11px;
    cursor: pointer;
}

.painel .end input[type="date"]::-webkit-calendar-picker-indicator {
    color: red;
}

.painel .end buttom {
    border-radius: 4px;
    color: white;
    font-size: 11px;
    font-weight: 700;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: #ec3237;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition: 1s;
}

.painel .end buttom:hover {
    background-color: #c4292e;
}

.bottomAreaInfo {
    overflow-y: auto;
    border-top: solid 1px gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
}

/* BasicPageStructure.module.css */

.button {
    border-radius: 4px;
    color: white;
    font-size: 11px;
    font-weight: 700;
    padding: 6px 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    transition: background-color 0.3s ease;
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

/* Estilo para o primeiro botão (vermelho) */
.primaryButton {
    background-color: hsl(358, 65%, 47%);
    border: none;
}

.primaryButton:hover {
    background-color: hsla(358, 65%, 47%, 0.863);

}

/* Estilo para os outros botões (padrão) */
.defaultButton {
    background-color: white;
    color: #555555;
    border: solid 0.1px #CCCCCC;
}

.defaultButton:hover {
    background-color: #f5f5f5;
}

/* Ícone dentro dos botões */
.icon {
    font-size: 13px;
}


.returnPainelArrow {
    padding-top: 15px;
    padding-left: 0px;
    padding-right: 0px;
    width: 96%;
    margin-left: -5px;
}

.returnPainelArrow a {
    width: 70px;
    display: flex;
    justify-content: start;
    align-items: center;
    text-decoration: none;
    color: #ec3237;
    font-size: 12px;
    transition: 0.5s;
}

.returnPainelArrow a:hover {
    color: #c4292e;
}

@media (max-width:700px) {
    .home {
        width: 100%;
        height: 100%;
    }
}

@media (max-width:450px) {
    .painel {
        flex-direction: column;
        align-items: start;
        padding-bottom: 50px;
    }

    .painel .end {
        width: 100%;
        padding-right: 0px;
        justify-content: start;
        padding-top: 10px;
    }
}