.all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.home {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-y: auto;
}

/*  */
.topAreaInfo {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.topAreaInfo .start {
    height: 100%;
}

.topAreaInfo .start h1 {
    font-size: 18px;
    margin: 0px;
    padding: 0px;
}

.topAreaInfo .start p {
    padding-right: 20px;
    font-size: 11px;
    margin: 0px;
    padding: 0px;
    color: gray;
}

.topAreaInfo .end {
    height: 100%;
    width: 500px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
}

.topAreaInfo .end a {
    border-radius: 4px;
    color: white;
    font-size: 11px;
    font-weight: 700;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: #ec3237;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition: 1s;
}

.topAreaInfo .end a {
    text-decoration: none;
    background-color: #c4292e;
}

/* Horários */
.horariosList {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-y: auto;
}

.horarioItem {
    width: 95%;
    border-radius: 7px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border: 1px solid rgba(218, 218, 218, 0.801);
    padding-top: 20px;
    padding-bottom: 30px;
}

.materiaContainer {
    margin-bottom: 1rem;
}

.materiaContainer button {
    margin-top: 0.5rem;
}

.semana_all {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.semana {
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-top: 1px solid gray;
    color: gray;
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.semana:hover {
    background-color: rgba(167, 167, 167, 0.219);
}

.horarioItem h2{
    width: 90%;
    padding-left: 10px;
    font-size: 20px;
    color: #ec3237;
}

.horario {
    width: 90%;
    color: #423232;
}

.horario:hover {
    color: rgb(185, 3, 3);
}

.horario_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}


.form_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 25px;
    position: relative;
}

.form_header h2 {
    color: rgb(214, 36, 36);
    font-size: 24px;
    padding-left: 20px;
    background-color: gre;
}

.form_header button{
    position: absolute;
    top: 0px;
    right: 0px;
    color: rgb(114, 114, 114);
    background-color: transparent;
    border: none;
    padding: 20px 20px;
    cursor: pointer;
    font-size: 25px;
    transition: 1s;
}

.form_header button:hover {
    color: #c4292e;
}


.horario_bottom {
    padding-left: 40px;
}

.form {
    background-color: rgba(27, 27, 29, 0.452);
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form_container {
    background-color: white;
    width: 70%;
    height: 70%;
    border-radius: 10px;
}


.form_content {
    overflow-y: auto;
    height: 80%;
}

.filters {
    width: 95%;
    display: flex;
    justify-content: start;
    align-items: center;
}

.filters div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 10px;
}

.filters div label {
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    margin-bottom: 5px;
    color: #c72a2f;
}

.filters select{
    cursor: pointer;
    border-radius: 5px;
    width: 150px;
    padding: 7px 15px 7px 15px;
    border: solid 1px rgba(226, 226, 226, 0.863);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    color: gray;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    background-color: white;
}

.filters select:hover{
    outline: none;
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.filters select:focus{
    outline: none;
    border: 1px solid #d4d4d4;
}

.actionButtons {
    width: 98.5%;
    height: 50px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
}

.actionButtons button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #7a0509;
    background-color: #ff8387ee;
    font-size: 15px;
    transition: 1s;
    cursor: pointer;
}

.actionButtons button:hover {
    color: #7a0509b7;
    background-color: #ff83879d;
}

.actionButtons .updateButton {
    background-color: #fff383ee;
    color: #665d07ee;
}

.actionButtons .updateButton:hover {
    background-color: #dacf70ee;
}

.notFound {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}
.notFound p {
    font-size: 15px;
    font-weight: 600;
    color: #a8a8a8;
}
.notFound img {
    width: 200px;
}

@media (max-width :700px) {
    .form_container {
        background-color: white;
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }
    .home {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }

    .actionButtons {
        width: 97%;
    }
}