.all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.home {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-y: auto;
}


.returnPainelArrow {
    padding-top: 20px;
    width: 96%;
}

.returnPainelArrow a {
    width: 70px;
    display: flex;
    justify-content: start;
    align-items: center;
    text-decoration: none;
    color: #ec3237;
    font-size: 12px;
    transition: 0.5s;
}

.returnPainelArrow a:hover {
    color: #c4292e;
}


/*  */
.topAreaInfo {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 20px;
}

.topAreaInfo .start {
    height: 100%;
}

.topAreaInfo .start h1 {
    font-size: 18px;
    margin: 0px;
    padding: 0px;
}

.topAreaInfo .start p {
    padding-right: 20px;
    font-size: 11px;
    margin: 0px;
    padding: 0px;
    color: gray;
}

.topAreaInfo .end {
    height: 100%;
    width: 500px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
    color: white;
}



.topAreaInfo .end a {
    border-radius: 4px;
    color: white;
    font-size: 11px;
    font-weight: 700;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: #ec3237;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition: 1s;
}

.topAreaInfo .end a {
    text-decoration: none;
    background-color: #c4292e;
}

.form {
    width: 100%;
    height: 100%;
    border-top: 1px solid gray;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.form .layout {
    width: 90%;
    height: 300px;
    overflow-y: auto;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.form .layout p {
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    color: gray;
    font-weight: 600;
}

.form .layout input {
    width: 90%;
    margin-top: 5px;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    transition: border-bottom-color 0.3s;
    font-size: 16px;
}

.form .layout input:focus {
    outline: none;
    border-bottom-color: #ec3237;
    /* Cor quando em foco */
}

.form .layout select {
    width: 100%;
    margin-top: 5px;
    padding: 10px;
    font-size: 16px;
    border-radius: 3px;
    border: 1px solid #ccc;
    transition: 1s;
    color: gray;
}

.form .layout select:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

.form .layout textarea {
    width: 90%;
    margin-top: 5px;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    transition: border-bottom-color 0.3s;
    font-size: 16px;
    resize: none;
}

.form .layout textarea:focus {
    outline: none;
    border-bottom-color: #ec3237;
    /* Cor quando em foco */
}

.form .layout .date {
    width: 50%;
    color: gray;
    cursor: pointer;
}

.form .layout2 {
    width: 97%;
    padding: 20px 0px 20px 30px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    gap: 5px;
}

.form .layout2 button {
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: end;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    border-radius: 5px;
    padding: 8px 15px 8px 15px;
    cursor: pointer;
    transition: 1s;
    background-color: #ec3237;
    color: white;
}

.form .layout2 button:hover {
    background-color: #c22a2f;
}
.checkboxGroup {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 10px; /* Espaçamento entre os itens */
    padding: 10px; /* Espaçamento interno */
}

.checkboxLabel {
    display: flex;
    align-items: center; /* Alinha verticalmente no centro */
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.checkboxLabel input[type="radio"] {
    margin-right: 10px; /* Espaçamento entre o checkbox e o texto */
    appearance: none; /* Remove a aparência padrão do rádio */
    -webkit-appearance: none; /* Remove a aparência padrão do rádio no WebKit */
    -moz-appearance: none; /* Remove a aparência padrão do rádio no Firefox */
    width: 20px; /* Largura do rádio */
    height: 20px; /* Altura do rádio */
    border: 2px solid #ccc; /* Borda do rádio */
    border-radius: 50%; /* Cantos arredondados */
    cursor: pointer; /* Cursor de ponteiro */
    position: relative; /* Necessário para posicionar o pseudo-elemento */

    /* Remove a cor de fundo padrão do rádio */
    background-color: transparent; 
}

.checkboxLabel input[type="radio"]::after {
    content: "";
    position: absolute; /* Posiciona o pseudo-elemento dentro do rádio */
    top: 50%; /* Alinha verticalmente no centro */
    left: 50%; /* Alinha horizontalmente no centro */
    width: 12px; /* Largura da bolinha interna */
    height: 12px; /* Altura da bolinha interna */
    border-radius: 50%; /* Cantos arredondados */
    background-color: #db3434; /* Cor da bolinha interna */
    transform: translate(-50%, -50%) scale(0); /* Centraliza e escala para 0 quando não selecionado */
    transition: transform 0.2s ease-in-out; /* Transição suave */
}

.checkboxLabel input[type="radio"]:checked::after {
    transform: translate(-50%, -50%) scale(1); /* Escala para 1 quando selecionado */
}

.checkboxLabel input[type="radio"]:checked {
    border-color: #db3434; /* Cor da borda quando selecionado */
}

@media (max-width:700px) {
    .home {
        width: 100%;
        height: 100%;
    }
}