.form {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.form .layout {
    width: 100%;
    height: 100%;
    gap: 0px;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

}

.form .layout p {
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    color: gray;
    font-weight: 600;
}

.form .layout select {
    width: 90%;
    margin-top: 5px;
    padding: 6px;
    font-size: 16px;
    border-radius: 3px;
    border: 1px solid #ccc;
    transition: 1s;
    color: gray;
}

.form .layout select:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

.form .layout input {
    width: 90%;
    margin-top: 5px;
    padding: 6px;
    border: none;
    border-bottom: 1px solid #ccc;
    transition: border-bottom-color 0.3s;
    font-size: 16px;
    padding-bottom: 8px;
}

.form .layout input:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

.form .layout textarea {
    width: 90%;
    margin-top: 5px;
    padding: 4px;
    border: none;
    border-bottom: 1px solid #ccc;
    transition: border-bottom-color 0.3s;
    font-size: 16px;
    resize: none;
}

.form .layout textarea:focus {
    outline: none;
    border-bottom-color: #ec3237;
    /* Cor quando em foco */
}

.form .layout .date {
    width: 50%;
    color: gray;
    cursor: pointer;
}

.form .layout2 {
    width: 97%;
    padding: 20px 0px 20px 30px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.form .layout2 button {
    padding-top: 5px;
    padding-bottom: 5px;
    border:none;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: end;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    border-radius: 5px;
    padding: 8px 15px 8px 15px;
    cursor: pointer;
    transition: 1s;
    background-color: #ec3237;
    color: white;
}

.form .layout2 button:hover {
    background-color: #c22a2f;
}

/*  */
.formGroup {
    width: 90%;
    height: auto;
}

.controlButton {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 0px;
    padding-top: 10px;
    background-color: transparent;
}

.controlButton button {
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border: 1px solid rgb(223, 223, 223);
    background-color: transparent;
    color: gray;
    font: 14px solid gray;
    font-weight: 15px;
    font-family: "Quicksand", sans-serif;
    border-radius: 5px;
    display: flex;
    justify-content: certer;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    padding: 5px 14px 5px 14px;
}

.controlButton .submitButton {
    background-color: #c22a2f;
    color: white;
    transition: 1s;
}

.controlButton .submitButton:hover {
    background-color: #c22a2fbb;
}


@media (max-width:700px) {
    .home {
        width: 100%;
        height: 100%;
    }
    .formGroup {
        width: 95%;
    }
    
}