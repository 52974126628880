.userList {
    width: 87%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.userItem {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    border: 1px solid rgb(230, 230, 230);
}

.userItem:hover {
    transform: scale(1.005);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    border: 1px solid rgb(163, 163, 163);
}

.animateItem {
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.viewButton {
    margin-top: 10px;
    padding: 10px 15px;
    background: #ec3237;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.viewButton:hover {
    background: #CD2A2E;
}

.viewButton:active {
    background: #003f7f;
}



.filters {
    width: 89.5%;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
}

.filters div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filters div label {
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    margin-bottom: 5px;
    color: #c72a2f;
}

.filters select{
    cursor: pointer;
    border-radius: 5px;
    width: 150px;
    padding: 7px 15px 7px 15px;
    border: solid 1px rgba(226, 226, 226, 0.863);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    color: gray;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    background-color: white;
}

.filters select:hover{
    outline: none;
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.filters select:focus{
    outline: none;
    border: 1px solid #d4d4d4;
}

@media (max-width:700px) {
    .userList {
        width: 95%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        gap: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .viewButton {
        width: 100%;
    }
}