.notfound {
    height: 100vh;
    background: #ec3237;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 60px;
}
.notfound div{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    color: #ffde59;
}
.notfound p{
    font-weight: 800;
    font-size: 30px;
    color: white;
}
