/* CustomReactCalendar.css */

/* Estilos personalizados para o React Calendar */

.react-calendar {
    width: 100%;
    /* Remova 'max-width' ou ajuste conforme necessário */
    /* max-width: 500px; */
    background-color: #fff;
    border: none;
    font-family: Arial, sans-serif;
    line-height: 1.125em;
}

.react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin: 2px;
    cursor: pointer;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: #e6e6e6;
}

.react-calendar__tile--now {
    background: #ffff76;
    border-radius: 50%;
}

/* Removemos o background-color para permitir que a cor de fundo dos dias com status seja mantida */
.react-calendar__tile--active {
    border: 2px solid #006edc !important;
    border-radius: 6px;
    color: inherit !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    border: 2px solid #005bb5 !important;
}

.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}

/* Estilos para os dias com base no status */
.react-calendar__tile.presentDay {
    background-color: red !important;
    border-radius: 10px;
    color: white !important;
}

.react-calendar__tile.absentDay {
    background-color: rgb(245, 113, 113) !important;
    border-radius: 10px;
    color: white !important;
}

.react-calendar__tile.lateDay {
    background-color: orange !important;
    border-radius: 10px;
    color: white !important;
}

.react-calendar__tile.excusedDay {
    background-color: rgb(255, 252, 95) !important;
    border-radius: 10px;
    color: white !important;
}

/* Estilo para o dia atual (hoje) */
.react-calendar__tile.today {
    border: 2px solid rgb(209, 20, 20) !important;
    border-radius: 6px;
    color: gray;
}