/* AgendaViewComponent.module.css */

.viewContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-family: Arial, sans-serif;
  color: #333;
  max-width: 100%;
  overflow-x: auto;
}

/* Título principal */
.viewContainerTitle {
  color: #c52429;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 600;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 0.5rem;
}

/* Card de eventos */
.eventDetails {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: transform 0.2s ease;
  overflow: hidden;
}

.eventDetails:hover {
  transform: translateY(-2px);
}

.eventDetails h3 {
  font-size: 1.5rem;
  color: #222;
  margin: 0;
  font-weight: 500;
}

/* Lista de matérias */
.materiasList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 1rem;
  border-left: 2px solid #e0e0e0;
  margin-top: 1rem;
}

.materiaItem {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e0e0e0;
  font-size: 0.95rem;
  color: #555;
  background-color: #fafafa;
  padding: 1rem;
  border-radius: 6px;
}

.materiaItem p {
  margin: 0.3rem 0;
  word-break: break-word; /* Permite que textos longos sejam quebrados */
}

.materiaItem strong {
  color: #222;
  font-weight: 500;
}

/* Ações */
.actions {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

.customButton {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.customButton svg {
  font-size: 1.2rem;
}

.customButton:hover {
  transform: translateY(-2px);
}

.edit {
  background-color: #f1f3f4;
  color: #1a73e8;
}

.edit:hover {
  background-color: #e8f0fe;
}

.delete {
  background-color: #fbe9e7;
  color: #d32f2f;
}

.delete:hover {
  background-color: #f28b82;
}

.snackbar {
  font-size: 1rem;
  text-align: center;
  background-color: #1a73e8;
  color: white;
}

.materiaCampo {
  width: 100%;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
  min-height: 30px;
}
/* Responsividade */
@media (max-width: 768px) {
  .viewContainer {
    padding: 1.5rem 0.5rem;
    gap: 1rem;
  }

  .eventDetails {
    padding: 1rem;
    gap: 0.75rem;
    padding: 1rem;
    border-radius: 2px;
  }

  .viewContainerTitle {
    font-size: 1.5rem;
  }

  .materiasList {
    padding-left: 0.5rem;
  }

  .materiaItem {
    flex-direction: column; /* Ajusta a direção para coluna em telas menores */
  }

  .customButton {
    padding: 0.5rem;
    font-size: 0.85rem;
  }
}
