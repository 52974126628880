.all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.home {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-y: auto;
}

/*  */
.topAreaInfo {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.topAreaInfo .start {
    height: 100%;
}

.topAreaInfo .start h1 {
    font-size: 18px;
    margin: 0px;
    padding: 0px;
}

.topAreaInfo .start p {
    padding-right: 20px;
    font-size: 11px;
    margin: 0px;
    padding: 0px;
    color: gray;
}

.topAreaInfo .end {
    height: 100%;
    width: 500px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
}

.topAreaInfo .end a {
    border-radius: 4px;
    color: white;
    font-size: 11px;
    font-weight: 700;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: #ec3237;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition: 1s;
}

.topAreaInfo .end a {
    text-decoration: none;
    background-color: #c4292e;
}

/*  */
.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Garante que o popup está acima de outros elementos */
}

.popupContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    height: 80%;
    width: 80%;
    max-width: 900px;
    position: relative;
}

.closeButton {
    position: absolute;
    top: 0px;
    right: 0px;
    color: rgb(114, 114, 114);
    background-color: transparent;
    border: none;
    padding: 20px 20px;
    cursor: pointer;
    font-size: 25px;
    transition: 1s;
}

.closeButton:hover {
    color: rgb(196, 41, 41);
}

/* Formularios */
.formList {
    border-top: 1px solid gray;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    gap: 10px;
    padding-bottom: 20px;
}

.formItem {
    width: 90%;
    border-radius: 10px;
    border: 1px solid rgb(226, 226, 226);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.formItem .top {
    padding: 20px 10px 20px 20px;
    display: flex;
    justify-content: space-between;
}

.formItem .top .left {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    width: 80%;
}

.formItem .top .left a {
    text-decoration: none;
    font-weight: 600;
    color: rgb(39, 38, 38);
    display: inline-block;
    max-width: 80%; /* Garante que o link ocupe o espaço disponível */
    white-space: nowrap; /* Não permite quebra de linha */
    overflow: hidden; /* Oculta o excesso de texto */
    text-overflow: ellipsis;
}

.formItem .top .left .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid rgb(226, 226, 226);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.formItem .top .right {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
}

.formItem .top .right button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #7a0509;
    background-color: #ff8387ee;
    font-size: 15px;
    transition: 1s;
    cursor: pointer;
}

.formItem .top .right button:hover {
    color: #7a0509b7;
    background-color: #ff83879d;
}

.formItem .top .right .updateButton {
    background-color: #fff383ee;
    color: #665d07ee;
}

.formItem .top .right .updateButton:hover {
    background-color: #dacf70ee;
}

.formItem .bottom {
    padding: 10px 10px 10px 10px;
}

.senderButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.senderButton button{
    background-color: #ec3237;
    width: 97%;
    border: 1px solid rgb(231, 231, 231);
    height: 40px;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    transition: 1s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.senderButton button:hover{
    background-color: #ac2125;
}

@media (max-width:900px) {
}

@media (max-width:700px) {
    .home {
        width: 100%;
        height: 100%;
        border-radius: none;
    }
    .formItem {
        width: 95%;
    }
    .popupContent {
        background: white;
        padding: 20px;
        border-radius: 0px;
        height: 96%;
        width: 100%;
        max-width: 100%;
    }
    .formItem .top .left {
        width: 70%;
    }
}

@media (max-width: 500px) {
    .formItem .top .left {
        width: 60%;
    }
}