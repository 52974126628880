/* ItemCardapioCreateComponent.module.css */

/* Animação para abrir a popup */
@keyframes slideDown {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Animação para fechar a popup */
@keyframes slideUp {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}

/* Animação para a seta flipDown */
@keyframes flipDown {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

/* Animação para a sobreposição aparecer */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Animação para a sobreposição desaparecer */
@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

/* Estilos do contêiner pai */
.container {
    position: relative;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    min-height: 400px;
    height: max-content;
}

.return {
    display: flex;
    justify-content: start;
    align-items: center;
}

.return button {
    background-color: transparent;
    color: rgb(51, 47, 47);
    border: none;
    font-size: 25px;
    cursor: pointer;
}


/* Estilos das ações (botão de adicionar) */
.actions {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    min-height: 50px;
}

.openButton {
    padding: 10px;
    border-radius: 50%;
    border: 1px solid rgb(226, 226, 226);
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    /* Fundo do botão */
    transition: background-color 0.3s ease;
    font-size: 24px;
    /* Aumenta o tamanho do símbolo '+' */
}

.openButton:hover {
    background-color: #e0e0e0;
    /* Cor de fundo ao passar o mouse */
}

/* Estilos da popup */
.popup {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: white;
    padding: 20px;
    border-radius: 3px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    overflow: auto;
    max-height: 100%;
    animation: slideDown 0.5s ease-out forwards;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    width: 100%;
    height: 100%;
    z-index: 1000;
    box-sizing: border-box;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.popup h2 {
    font-size: 26px;
}

.closing {
    animation: slideUp 0.3s forwards;
}

/* Estilos para a lista de categorias */
.categoryList {
    margin-top: 20px;
}

.categoryList ul {
    list-style-type: none;
    padding: 0;
}

.categoryItem {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
}

.categoryHeader {
    background-color: #f9f9f9;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.categoryHeader:hover {
    background-color: #e0e0e0;
}

.itemList {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.item {
    padding: 10px;
    border-top: 1px solid #ddd;
}

.item:hover {
    background-color: #f0f0f0;
}

.noItems {
    padding: 10px;
    font-style: italic;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* Espaçamento entre os inputs */
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.formGroup input[type="text"],
.formGroup input[type="color"] {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    min-height: 50px;
}

.formGroup input[type="text"]:focus,
.formGroup input[type="color"]:focus {
    outline: none;
    border-color: #007bff;
    /* Azul ao focar no input */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.addButton {
    background-color: #c4292e;
    /* Verde */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.addButton:hover {
    background-color: #a11e22;
    /* Verde mais escuro no hover */
}


/* Animação para abrir a popup */
@keyframes slideDown {
    0% {
        transform: translateY(-100%);
        /* Inicia fora do container */
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        /* Se move para ocupar o container */
        opacity: 1;
    }
}

/* Animação para fechar a popup */
@keyframes slideUp {
    0% {
        transform: translateY(0);
        /* Inicia dentro do container */
        opacity: 1;
    }

    100% {
        transform: translateY(-100%);
        /* Sai para fora do container */
        opacity: 0;
    }
}


@media (max-width:700px) {
    .container {
        min-height: 550px;
    }
}

@media (min-height: 500px) {
    .container {
        min-height: 420px;
    }
}

@media (min-height:600px) {
    .container {
        min-height: 520px;
    }
}

@media (min-height:700px) {
    .container {
        min-height: 620px;
    }
}

@media (min-height:800px) {
    .container {
        min-height: 720px;
    }
}