.screen {
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    position: fixed; /* Fixa o elemento na viewport */
    z-index: 9999999; /* Garante que fique à frente */
}

.screen div{
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}