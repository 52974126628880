/* Container principal */
.all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.home {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    background-color: #ffffff;
}

/* Navegação de retorno */
.returnPainelArrow {
    padding-top: 20px;
    width: 96%;
}

.returnPainelArrow a {
    width: 70px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ec3237;
    font-size: 12px;
    transition: color 0.3s;
}

.returnPainelArrow a:hover {
    color: #c4292e;
}

/* Cabeçalho */
.topAreaInfo {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.topAreaInfo .start h1 {
    font-size: 18px;
    margin: 0;
    color: #333;
}

.topAreaInfo .start p {
    font-size: 11px;
    color: gray;
    margin: 0;
}

.topAreaInfo .end button {
    border-radius: 4px;
    color: white;
    font-size: 11px;
    font-weight: bold;
    padding: 6px 10px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ec3237;
    display: flex;
    align-items: center;
    gap: 4px;
    border: none;
    transition: background-color 0.3s;
}

.topAreaInfo .end button:hover {
    background-color: #d32f2f;
}

/* Área de seleção de série */
.seriesList {
    width: 90%;
    padding: 15px 0;
    display: flex;
    justify-content: start;
}
.seriesList div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.seriesList label {
    color: #c62a2f;
    font-weight: 600;
}

.selectSerie {
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    outline: none;
    transition: border-color 0.3s;
    color: gray;
}

.selectSerie:focus {
    border-color: #007bff;
}

/* Lista de ações */
.actionsList {
    width: 95%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.actionsList button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s, color 0.3s;
}

.actionsList .updateButton {
    background-color: #fff383;
    color: #665d07;
}

.actionsList .updateButton:hover {
    background-color: #dacf70;
}

.actionsList .removeButton {
    background-color: #ff8387;
    color: #7a0509;
}

.actionsList .removeButton:hover {
    background-color: #ff6a6f;
}

/* Cartão de matérias */
.materiasList {
    width: 89%;
    margin-top: 20px;
    padding-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
    border: 1px solid rgba(160, 160, 160, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.materiaCard {
    width: 95%;

    padding: 10px;
}

.materiaCard .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(160, 160, 160, 0.3);
}

.materiaCard .card .right {
    display: flex;
    align-items: center;
    gap: 10px;
}

.materiaCard .card h2 {
    font-size: 15px;
    color: #c72a2f;
    font-weight: bold;
}

.materiaCard .card p {
    color: rgb(185, 185, 185);
    font-size: 12px;
}

.materiaCard .card .colorBoll {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

/* Not Found */
.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #a8a8a8;
}

.notFound p {
    font-size: 16px;
    font-weight: bold;
}

.notFound img {
    width: 200px;
    margin-top: 10px;
}

.notFound button {
    width: 100%;
    height: 35px;
    margin-top: 10px;
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 2px solid rgb(199, 199, 199);
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    color: rgb(121, 121, 121);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.8s;
}

.notFound button:hover {
    background-color:#e7e7e7 ;
}

/* Modal */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.modalContent h2 {
    margin-top: 0;
    font-size: 18px;
    color: #333;
}

.modalContent form {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow-y: auto;
}

.modalContent form div {
    margin-bottom: 10px;
}

.modalContent form label {
    font-size: 13px;
    color: #666;
}

.modalContent form input,
.modalContent form select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.modalContent .color {
    width: 40px;
    height: 40px;
    border: 1px solid #e0e0e0;
}

.modalContent form button[type="button"] {
    background: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
}

.modalContent form button[type="submit"] {
    background: #c72a2f;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 10px;
}

.closeButton {
    display: flex;
    justify-content: flex-end;
    color: rgb(95, 95, 95);
    font-size: 20px;
}

.closeButton button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #999;
    transition: color 0.3s;
}

.closeButton button:hover {
    color: #333;
}

/* Responsividade */
@media (max-width: 700px) {
    .home {
        width: 100%;
        height: 100%;
    }

    .modalContent {
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding-top: 100px;
    }
    .modalContent form {
        max-height: 550px;
    }
}
