.container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.boletimTabela {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.boletimTabela thead tr {
    background-color: #ec3237; /* Verde suave */
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}

.boletimTabela th,
.boletimTabela td {
    padding: 12px 15px;
    border: 1px solid #ddd;
}

.boletimTabela tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Cinza claro */
}

.boletimTabela tbody tr:hover {
    background-color: #f1f1f1; /* Destaque ao passar o mouse */
}

.boletimTabela textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none; /* Evita redimensionamento */
}

.boletimTabela textarea:focus {
    border-color: #ec3237; /* Verde ao focar */
    outline: none;
    box-shadow: 0 0 5px #9e1c20; /* Efeito de foco */
}

.boletimTabela select {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.boletimTabela select:focus {
    border-color: #ec3237; /* Cor de destaque ao focar */
    outline: none;
    box-shadow: 0 0 5px #9e1c20; /* Efeito de foco */
}

.boletimTabela select:hover {
    background-color: #f9f9f9; /* Cor de fundo ao passar o mouse */
}

.title {
    font-size: 24px;
    color: #333;
    text-transform: uppercase;
    margin-bottom: 15px;
    border-bottom: 2px solid #ec3237; /* Linha de destaque */
    padding-bottom: 5px;
}

.guidelines {
    list-style-type: none;
    padding: 0;
    margin: 15px 0;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
}

.guidelines li {
    margin-bottom: 10px;
    padding: 10px;
    border-left: 4px solid #ec3237; /* Destaque à esquerda */
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.guidelines li strong {
    color: #ec3237; /* Destaque para as siglas */
}

@media screen and (max-width: 768px) {

    .areaGeralCell {
        font-weight: bold; /* Deixa o texto em negrito */
        font-size: 18px; /* Aumenta um pouco o tamanho da fonte */
        color: #ec3237; /* Muda a cor do texto para destacar */
        border-left: 4px solid #ec3237; /* Adiciona uma borda à esquerda */
    }

    .boletimTabela {
        font-size: 14px;
    }

    .boletimTabela thead {
        display: none; /* Esconde o cabeçalho em telas pequenas */
    }

    .boletimTabela tbody tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .boletimTabela td {
        display: flex;
        flex-direction: column;
        padding: 10px;
        font-size: 14px;
        border: none;
        border-bottom: 1px solid #ddd;
    }

    .boletimTabela td:before {
        content: attr(data-label); /* Adiciona o cabeçalho como rótulo */
        font-weight: bold;
        text-transform: uppercase;
        color: #555;
        margin-bottom: 5px;
    }

    .boletimTabela td:last-child {
        border-bottom: none;
    }

    .guidelines {
        font-size: 14px;
    }
}

