.legend {
    border-radius: 10px;
    padding: 10px 0px 10px 20px;
    border: 1px solid rgb(230, 230, 230);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    font-size: 14px;
}

.legend li {
    padding-bottom: 5px;
}

.calendar {
    border-radius: 10px;
    padding: 10px 0px 10px 20px;
    border: 1px solid rgb(230, 230, 230);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    font-size: 14px;
    padding-right: 20px;
}

.legend ul{
    list-style-type: none;
    padding: 0; /* Remove a indentação padrão */
    margin: 0; /* Remove a margem padrão */
    font-weight: 700;
    color: rgb(49, 49, 49);
}

.legendColor {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
}

/* Correção das cores das legendas para corresponder aos status */
.legendGreen {
    background-color: #4caf50;
    /* Verde para Presente */
}

.legendRed {
    background-color: #f44336;
    /* Vermelho para Ausente */
}

.legendOrange {
    background-color: #ff9800;
    /* Laranja para Atrasado */
}

.legendBlue {
    background-color: #2196f3;
    /* Azul para Justificado */
}

.presentDay {
    background-color: #4caf50 !important; /* Verde */
    border-radius: 10px;
    color: white !important;
}

.absentDay {
    background-color: #f44336 !important; /* Vermelho */
    border-radius: 10px;
    color: white !important;
}

.excusedDay {
    background-color: #2196f3 !important; /* Azul */
    border-radius: 10px;
    color: white !important;
}

.frequenciaAll {
    display: flex;
    width: 100%;
    justify-content: center;
}

/* Layout geral */
.all {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
}

.left {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 15px;
    padding-right: 0px;
}

/* Estilos para os filtros */
.filters {
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
}

.filters label {
    margin-right: 8px;
}

.filters select {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

/* Estilos para a tabela */
.right table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
}

.right th,
.right td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
}

.right th {
    background-color: #f2f2f2;
}

/* Estilos para os botões de status */
.statusButton {
    width: 30px;
    height: 30px;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    position: relative;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    padding: 0;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.statusButton:hover {
    border-color: #888;
}

.statusButton.active {
    color: white;
}

/* Cores específicas para cada status quando ativo */
.statusButton.active.present {
    background-color: #388e3c;
    /* Verde escuro */
    border-color: #388e3c;
}

.statusButton.active.absent {
    background-color: #d32f2f;
    /* Vermelho escuro */
    border-color: #d32f2f;
}

.statusButton.active.late {
    background-color: #f57c00;
    /* Laranja escuro */
    border-color: #f57c00;
}

.statusButton.active.excused {
    background-color: #1976d2;
    /* Azul escuro */
    border-color: #1976d2;
}

/* Indicador de "✓" dentro do botão */
.statusButton::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.statusButton.active::after {
    content: '✓';
    font-weight: bold;
    font-size: 18px;
}

.right {
    border-radius: 10px;
    padding: 10px 0px 10px 20px;
    border: 1px solid rgb(230, 230, 230);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    font-size: 14px;
    padding-right: 20px;
    height: auto;
}

.right h2 {
    color: #423b3b;
}

.filters {
    overflow-x: auto;
    overflow-y: hidden;
}

.filters div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: gray;
    gap: 8px;
}

.filters label{
    display: flex;
    flex-direction: column;
    color: rgb(175, 28, 28);
    font-weight: 700;
    font-size: 15px;
    width: 100%;
    text-align: start;
    margin-left:5px;
}

.filters select{
    display: flex;
    flex-direction: column;
    color: gray;
    font-weight: 600;
    padding: 5px 10px 5px 10px;
    outline: none;
}


.lessonField {
    border-radius: 10px;
    padding: 10px 0px 10px 20px;
    border: 1px solid rgb(230, 230, 230);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    font-size: 14px;
    padding-right: 20px;
    height: auto;
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.lessonField {
    border-radius: 10px;
    padding: 10px 0px 10px 20px;
    border: 1px solid rgb(230, 230, 230);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    font-size: 14px;
    padding-right: 20px;
    height: auto;
    min-height: 100px;
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.lessonField h2 { 
    color: #423b3b;
}

.lessonField .react_quill_input {
    height: auto;
    min-height: 100px;
}

.lessonField button{
    width: 120px;
    height: 40px;
    border-radius: 5px;
    background-color: rgb(255, 211, 15);
    color: rgb(90, 74, 5);
    margin-top: 10px;
    border: 1px solid rgb(173, 173, 173);
    cursor: pointer;
    font-weight: 600;
    transition: 1s;
}

.lessonField button:hover{
    background-color: rgb(218, 180, 14);
}

@media (max-width:1400px) { 
    .all {
        width:85%;
    }
}

@media (max-width:950px) {
    .all {
        display: flex;
        flex-direction: column;
        width: 95%;
    }
    .left {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 20px;
        gap: 15px;
        padding-right: 0px;
    }
}

/* Responsividade para a tabela */
@media (max-width: 768px) {

    .right table,
    .right thead,
    .right tbody,
    .right th,
    .right td,
    .right tr {
        display: block;
    }

    .right th {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .right tr {
        margin-bottom: 15px;
    }

    .right td {
        border: none;
        position: relative;
        padding-left: 50%;
        text-align: left;
    }

    .right td::before {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
    }

    .right td:nth-of-type(1)::before {
        content: "Nome";
    }

    .right td:nth-of-type(2)::before {
        content: "Matrícula";
    }

    .right td:nth-of-type(3)::before {
        content: "Série";
    }

    .right td:nth-of-type(4)::before {
        content: "Turno";
    }

    .right td:nth-of-type(5)::before {
        content: "Presente";
    }

    .right td:nth-of-type(6)::before {
        content: "Ausente";
    }

    .right td:nth-of-type(7)::before {
        content: "Justificado";
    }

    .right td:nth-of-type(8)::before {
        content: "Justificado";
    }
}

@media (max-width:700px) {
    .home {
        width: 100%;
        height: 100%;
    }

    .all {
        display: flex;
        flex-direction: column;
        width: 98%;
    }
}

@media (max-width:450px) {
    .topAreaInfo {
        flex-direction: column;
        align-items: start;
        padding-bottom: 50px;
    }

    .topAreaInfo .end {
        width: 100%;
        padding-right: 0px;
        justify-content: start;
        padding-top: 10px;
    }
}

/* Adicionando espaçamento entre legendas */
.legendColor+span {
    margin-right: 15px;
}