
.top {
    border-bottom: 1px solid gray;
    padding: 10px;
}

.returnPainelArrow {
    width: 100%;
    margin-bottom: 10px;
}

.returnPainelArrow a {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #ec3237;
    text-decoration: none;
    transition: color 0.3s;
}

.returnPainelArrow a:hover {
    color: #c4292e;
}

.topAreaInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 0px;
}

.topAreaInfo .start h1 {
    font-size: 24px;
    color: #333;
    margin: 0;
    margin: 0px;
}

.topAreaInfo .start p {
    font-size: 12px;
    color: #888;
    margin: 0px;
}

.topAreaInfo .end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-family: "Quicksand", sans-serif;
}

.formGroup {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 15px;
    gap: 10px;
    width: 90%;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}

.formGroup h4 {
    font-size: 16px;
    color: #666;
    margin-bottom: 5px;
}

.formGroup input {
    width: 98%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s;
}

.formGroup input:focus {
    border-color: #ec3237;
    outline: none;
}

.alimentacaoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.refeicaoContainer {
    background-color: #f9f9f9;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    width: 90%;
}

.inputRefeicao {
    width: 98%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s;
}

.inputRefeicao:focus {
    border-color: #ec3237;
    outline: none;
}

.refeicaoContainer h3 {
    margin-bottom: 15px;
    color: #333;
    font-size: 18px;
}

.refeicaoContainer button {
    margin-top: 10px;
    padding: 11px 13px 11px 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.diaContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.diaContainer h4 {
    font-size: 16px;
    color: #666;
    margin-bottom: 5px;
}

.removeButton,
.addButton {
    background-color: #ec3237;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.removeButton:hover,
.addButton:hover {
    background-color: #c4292e;
}

.botoesDeControle {
    width: 92%;
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 10px;
    padding-bottom: 40px;
}


.botoesDeControle .addButton {
    background-color: #ec3237;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.botoesDeControle .addButton:hover {
    background-color: #c4292e;
}

.botoesDeControle button {
    background-color: #ffd634;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.botoesDeControle button:hover {
    background-color: #caa81f;
}


.editorWrapper {
    margin-top: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    background-color: #f9f9f9;
    min-height: 250px;
}

.editor {
    max-height: 200px;
    padding: 10px;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    overflow-y: auto;
}

.toolbar {
    border: 1px solid #e0e0e0;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    background-color: #f1f1f1;
    padding: 5px;
    margin-bottom: -1px;
}

.editorPlaceholder {
    color: #aaa;
}

.observacao h4 {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

@media (max-width: 768px) {
    .home {
        width: 100%;
        padding: 15px;
    }

    .form {
        gap: 15px;
    }

    .botoesDeControle {
        justify-content: center;
        flex-direction: column;
    }

    .formGroup {
        width: 85%;
    }

    .formGroup input{
        width: 94%;
    }

    .refeicaoContainer {
        width: 85%;
    }
}

@media (max-width: 480px) {
    .topAreaInfo {
        flex-direction: column;
        align-items: flex-start;
    }

    .topAreaInfo .end {
        width: 100%;
        justify-content: flex-start;
        padding-top: 10px;
    }

    .formGroup input {
        font-size: 14px;
    }

    .alimentacaoContainer {
        gap: 15px;
    }

    .refeicaoContainer {
        padding: 10px;
    }
}

@media (max-width:700px) {
    .home {
        width: 100%;
        max-height: AUTO;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        padding: 0px;
        gap: 20px;
        overflow-y: auto;
        overflow-x: hidden
    }
}