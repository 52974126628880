@keyframes slideDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.allScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.247);
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    background-color: white;
    width: 70%;
    height: 70%;
    border-radius: 10px;
    animation: slideDown 0.5s ease-out;
}

.content .itemTop {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px gray;
}

.content .itemTop h2 {
    width: 90%;
    font-size: 17px;
    font-weight: 700;
    color: rgb(77, 77, 77);
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 2px;
}

.content .itemTop button {
    border: none;
    background-color: transparent;
    font-size: 25px;
    color: gray;
    cursor: pointer;
    transition: 1s;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-right: 15px;
}

.content .itemTop button:hover {
    color: rgb(206, 0, 0);
}

.categories {
    width: 95%;
    border-radius: 10px;
    border: solid 0.5px rgba(230, 230, 230, 0.89);
    padding-top: 5px;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.categories .content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.categories .content .delete {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 14px;
    padding-top: 10px;
    border-bottom: solid 0.3px rgba(226, 226, 226, 0.5);
    cursor: pointer;
    transition: 1s;
}

.categories .content .delete:hover {
    background-color: rgba(169, 169, 169, 0.13);
}

.itemCenter {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: start;
    overflow-y: auto;
}

.categories .item {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 85%;
    gap: 10px;
    border-bottom: solid 0.3px rgba(226, 226, 226, 0.5);
    padding-bottom: 15px;
    padding-top: 10px;
    cursor: pointer;
    transition: 1s;
    padding-right: 5px;
    padding-left: 5px;
}

.categories .item:hover {
    background-color: rgba(169, 169, 169, 0.13);
}

.categories .item .colorBoll {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.categories .item .nameCat {
    color: rgb(168, 168, 168);
    font-size: 14px;
    font-weight: 500;
}

.itemBottom {
    height: 10%;
    background-color: #ec3237;
}

.itemBottom button {
    width: 100%;
    height: 100%;
    border: none;
    color: white;
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
    background-color: transparent;
    cursor: pointer;
    transition: 1s;
}

.itemBottom button:hover {
    background-color: #ffffff50;
}

/* Estilizações existentes */

.deleteModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.deleteModal {
    background: white;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.deleteModal p{
    width: 80%;
    margin-top: 0px;
    color: gray;
    font-weight: 300;
    text-align: center;
    font-size: 10px;
    font-weight: 10px;
}

.deleteModal h3 {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
}

.deleteModalActions {
    width: 50%;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    gap: 5px;
}

.deleteModalActions button {
    padding: 4px 25px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 9px;
}

.deleteModalActions button:first-child {
    background: #ff4d4d;
    color: white;
}

.deleteModalActions button:last-child {
    background: #ccc;
}


@media (max-width:700px) {
    .allScreen {
        z-index: 999;
    }

    .content {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }
}