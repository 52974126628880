.all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.home {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-y: auto;
}

.topAreaInfo {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.topAreaInfo .start {
    height: 100%;
}

.topAreaInfo .start h1 {
    font-size: 18px;
    margin: 0;
    padding: 0;
}

.topAreaInfo .start p {
    padding-right: 20px;
    font-size: 11px;
    margin: 0;
    padding: 0;
    color: gray;
}

.topAreaInfo .end {
    height: 100%;
    width: 500px;
    /* Ajuste conforme necessário */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
}

.topAreaInfo .end input[type="date"] {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 3px;
    padding-left: 3px;
    font-size: 11px;
    cursor: pointer;
}

.topAreaInfo .end input[type="date"]::-webkit-calendar-picker-indicator {
    color: red;
}

.topAreaInfo .end button {
    border-radius: 4px;
    color: white;
    font-size: 11px;
    font-weight: 700;
    padding: 6px 8px;
    cursor: pointer;
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: #ec3237;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition: background-color 0.3s;
    border: #f9f9f9;
}

.topAreaInfo .end button:hover {
    background-color: #c4292e;
}

.bottomAreaInfo {
    overflow-y: auto;
    border-top: solid 1px gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
}

.calendarWrapper {
    width: 95%;
}

.reactCalendar {
    width: 100% !important;
    border-radius: 10px;
    padding: 5px;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    border: solid 0.5px rgba(238, 238, 238, 0.63);
}

.reactCalendar .react-calendar__tile {
    position: relative; /* Necessário para o posicionamento do marcador */
    text-align: center;
    padding: 10px;
    font-size: 16px;
    background-color: #f9f9f9;
    border-radius: 4px;
    border: 1px solid transparent;
    transition: background-color 0.3s ease;
}

.reactCalendar .react-calendar__tile--now {
    background-color: #ffeeba;
}

.reactCalendar .react-calendar__tile--active {
    background-color: #007bff;
    color: white;
}

.reactCalendar .react-calendar__tile:hover {
    background-color: #ddd;
}

.reactCalendar .react-calendar__tile .eventMarker {
    position: absolute;
    bottom: 5px; /* Ajuste vertical */
    left: 50%;
    transform: translateX(-50%);
    width: 8px; /* Tamanho da bolinha */
    height: 8px;
    border-radius: 50%;
    background-color: inherit; /* Cor definida inline */
}

.reactCalendar .react-calendar__tile {
    text-align: center;
    padding: 10px;
    font-size: 16px;
    background-color: #f9f9f9;
    border-radius: 4px;
    border: 1px solid transparent;
    transition: background-color 0.3s ease;
}

.reactCalendar .react-calendar__tile--now {
    background-color: #ffeeba;
}

.reactCalendar .react-calendar__tile--active {
    background-color: #007bff;
    color: white;
}

.reactCalendar .react-calendar__tile:hover {
    background-color: #ddd;
}

.filterWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 10px;
    gap: 20px;
    padding-left: 3px;
    padding-right: 0px;
    white-space: nowrap;
}

.filterWrapper div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filterWrapper div label {
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    margin-bottom: 5px;
    color: #c72a2f;
}

.filterWrapper select {
    border-radius: 5px;
    width: 150px;
    padding: 7px 15px 7px 15px;
    border: solid 1px rgba(226, 226, 226, 0.863);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    color: gray;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    background-color: white;
}

.filterWrapper select:focus {
    outline: none;
    /* Garante que não haja borda ao focar */
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    /* Mantém a sombra ao focar */
}

.categories {
    width: 95%;
    border-radius: 10px;
    border: solid 0.5px rgba(230, 230, 230, 0.89);
    padding-top: 5px;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.categories h2 {
    width: 90%;
    font-size: 17px;
    font-weight: 700;
    color: rgb(77, 77, 77);
}

.categories .item {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 85%;
    gap: 10px;
    border-bottom: solid 0.3px rgba(226, 226, 226, 0.5);
    padding-bottom: 15px;
    padding-top: 10px;
    cursor: pointer;
}

.categories .item .colorBoll {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.categories .item .nameCat {
    color: rgb(168, 168, 168);
    font-size: 14px;
    font-weight: 500;
}

.reactCalendar .highlight {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reactCalendar .highlight .eventMarker {
    width: 8px;
    height: 8px;
    border-radius: 50%; 
    background-color: inherit;
    position: absolute;
    bottom: 4px;
}


.eventStatusText {
    position: absolute;
    bottom: -15px;
    font-size: 0.75em;
    font-weight: bold;
    color: inherit;
}

.reactCalendar .react-calendar__tile--now {
    background-color: rgba(128, 128, 128, 0.1) !important; /* Fundo cinza claro e transparente */
    border-radius: 0 !important; /* Remove o arredondamento */
    border: 1px solid rgba(128, 128, 128, 0.3) !important; /* Adiciona uma borda sutil */
    font-weight: bold !important; /* Deixa o texto um pouco mais destacado */
}

.reactCalendar .react-calendar__tile--now:hover {
    background-color: rgba(128, 128, 128, 0.15) !important; /* Efeito de hover sutil */
}

.category {
    margin-bottom: 10px;
    width: 90%;
    gap: 10px;
}

.category span {
    padding-left: 10px;
    color: 2px solid rgb(66, 59, 59);
    font-weight: 500;
}



@media (max-width:700px) {
    .home {
        width: 100%;
        height: 100%;
    }
}

@media (max-width:450px) {
    .topAreaInfo {
        flex-direction: column;
        align-items: start;
        padding-bottom: 50px;
    }

    .topAreaInfo .end {
        width: 100%;
        padding-right: 0px;
        justify-content: start;
        padding-top: 10px;
    }
}