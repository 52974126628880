.message {
    display: flex;
    align-items: center;
    padding: 12px 18px;
    border-radius: 6px;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
    max-width: 500px;
    margin: 10px auto;
    transition: all 0.3s ease;

    /* Comportamento sticky */
    position: sticky;
    top: 0;
    z-index: 1000;
}

.message.success {
    background-color: #e6f7e9;
    color: #1a7e34;
    border-left: 6px solid #28a745;
}

.message.error {
    background-color: #fdecea;
    color: #c12f28;
    border-left: 6px solid #e74c3c;
}

.message.warning {
    background-color: #fff5e1;
    color: #9f6000;
    border-left: 6px solid #ffa500;
}

.icon {
    font-size: 1.3rem;
    margin-right: 12px;
}


/* Animation for fade in/out */
@keyframes fadeInOut {
    0% { opacity: 0; transform: translateY(-10px); }
    10%, 90% { opacity: 1; transform: translateY(0); }
    100% { opacity: 0; transform: translateY(-10px); }
}
