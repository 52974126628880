
.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    background: white;
    padding: 5px 20px 15px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup .title{
    text-align: start;
}

.popup .title h2{
    font-size: 18px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.popup .title p{
    font-size: 13px;
    margin-top: 0px;
    padding-top: 0px;
    color: gray;
    font-weight: 600;
}


.popupActions {
    display: flex;
    justify-content: right;
    margin-top: 20px;
    gap: 8px;
}

.popupActions button {
    padding: 5px 15px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.popupActions button:first-child {
    color: black;
    background-color: #f0f0f0; /* Cor para o botão de cancelamento */
}

.popupActions button:last-child {
    background-color: #d9534f; /* Cor para o botão de confirmação */
    color: white;
}
