.form {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-height: auto;
}

.form .layout {
    width: 100%;
    max-height: 100%;
    gap: 20px;
    padding: 20px 0px 20px 0px;
}

.form .layout p {
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #6a6a6a;
    font-weight: 600;
}

.form .layout input[type="text"],
.form .layout textarea,
.form .layout .date {
    width: 90%;
    margin-top: 5px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
    font-size: 16px;
}

.form .layout input[type="text"]:focus,
.form .layout textarea:focus,
.form .layout .date:focus {
    outline: none;
    border-color: #ec3237;
}

.form .layout textarea {
    resize: none;
    height: 80px;
}

.form .imagedroparea {
    width: 86%;
    padding: 20px;
    margin-top: 10px;
    text-align: center;
    border: 2px dashed #ccc;
    border-radius: 6px;
    color: #6a6a6a;
    font-size: 16px;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s;
}

.form .imagedroparea:hover {
    border-color: #ec3237;
    background-color: #fafafa;
}

.form .layout2 {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
}

.form .layout2 button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    background-color: #ec3237;
    color: white;
    transition: background-color 0.3s;
}

.form .layout2 button:hover {
    background-color: #c22a2f;
}

/* Estilo para a área de arrastar e soltar imagem */
.imagedroparea {
    width: 100%;
    padding: 20px;
    margin-top: 10px;
    text-align: center;
    border: 2px dashed #ccc;
    border-radius: 6px;
    color: #6a6a6a;
    font-size: 16px;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s;
}

.imagedroparea:hover {
    border-color: #ec3237;
    background-color: #fafafa;
}

/* Estilo para a área de pré-visualização */
.previewContainer {
    width: 95%;
    padding: 20px 0px 20px 0px;
    position: relative;
    margin-top: 15px;
    text-align: center;
    background-color: #e2dada6e;
    border-radius: 10px;
}

.previewImage {
    max-width: 100%;
    max-height: 150px;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Estilo para o botão "X" */
.removeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 0, 0, 0.8);
    color: white;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
}

.removeButton:hover {
    background-color: rgba(255, 0, 0, 1);
}

.imagedroparea {
    width: 86%;
    padding: 20px;
    margin-top: 10px;
    text-align: center;
    border: 2px dashed #ccc;
    border-radius: 6px;
    color: #6a6a6a;
    font-size: 16px;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s;
}

.imagedroparea:hover, .imagedroparea.dragActive { /* Adiciona a borda vermelha durante o arraste */
    border-color: #ec3237;
    background-color: #fafafa;
}

.dragActive {
    border-color: #ec3237; /* Borda vermelha ao arrastar */
}

/* Estilo para o spinner */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #ec3237;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: auto;
}

/* Animação para o spinner */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


@media (max-width:700px) {
    .form .layout {
        width: 100%;
        height: 100%;
    }
}
