/* src/pages/TenantManagementPage.module.css */

.all {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}

.home {
  width: 95%;
  height: 95%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: auto;
}

.container {
  width: 95%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

h1 {
  color: #333;
}

.addButton {
  background-color: #4caf50;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.addButton:hover {
  background-color: #45a049;
}

.tableContainer {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
}

.table th,
.table td {
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.table th {
  background-color: #f4f4f4;
  color: #333;
}

.table tbody tr:hover {
  background-color: #f1f1f1;
}

.actions {
  display: flex;
  gap: 8px;
}

.viewButton,
.editButton,
.deleteButton {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.viewButton {
  background-color: #2196f3;
  color: #fff;
}

.editButton {
  background-color: #ffc107;
  color: #fff;
}

.deleteButton {
  background-color: #f44336;
  color: #fff;
}

.viewButton:hover {
  background-color: #1e88e5;
}

.editButton:hover {
  background-color: #ffb300;
}

.deleteButton:hover {
  background-color: #e53935;
}

.noData {
  text-align: center;
  padding: 20px;
  color: #888;
}

@media (max-width:700px) {
  .home {
      width: 100%;
      height: 100%;
  }
}