.all {
    width: 95%;
    padding-top: 20px;
}

.home {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-y: auto;
}


/*  */
.topAreaInfo {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.topAreaInfo .start {
    height: 100%;
}

.topAreaInfo .start h1 {
    font-size: 18px;
    margin: 0px;
    padding: 0px;
}

.topAreaInfo .start p {
    padding-right: 20px;
    font-size: 11px;
    margin: 0px;
    padding: 0px;
    color: gray;
}

.topAreaInfo .end {
    height: 100%;
    width: 500px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
}

.topAreaInfo .end a {
    border-radius: 4px;
    color: white;
    font-size: 11px;
    font-weight: 700;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: #ec3237;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition: 1s;
}

.topAreaInfo .end a {
    text-decoration: none;
    background-color: #c4292e;
}

.informesList {
    overflow-y: auto;
    height: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 15px;
}

.informeItem {
    background-color: #fffffF;
    border: 1px solid rgb(201, 201, 201);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    width: 95%;
    height: 200px;
    border-radius: 10px;
    padding-bottom: 20px;
    transition: 1s;
    cursor: pointer;
}

.informeItem:hover {
    background-color: #f1f1f1;
}

.topInformeItem {
    width: 98%;
    height: 50px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
}

.topInformeItem button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #7a0509;
    background-color: #ff8387ee;
    font-size: 15px;
    transition: 1s;
    cursor: pointer;
}

.topInformeItem button:hover {
    color: #7a0509b7;
    background-color: #ff83879d;
}

.topInformeItem .updateButton {
    background-color: #fff383ee;
    color: #665d07ee;
}

.topInformeItem .updateButton:hover {
    background-color: #dacf70ee;
}

.bottomInformeItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    padding-left: 20px;
    gap: 20px;

}


.bottomInformeItem .InfLeft {
    height: 100%;
    width: 80%;

}
.bottomInformeItem .InfLeft .topico{
    font-size: 14px;
    font-weight: 100;
    color: gray;
}
.bottomInformeItem .InfLeft .title{
    font-weight: 600;
    color: rgb(173, 50, 50);
}

.bottomInformeItem .InfLeft .desc{
    font-weight: 300;
    color: gray;
}

.bottomInformeItem .InfLeft .date {
    font-size: 12px;
    font-weight: 600;
    color: rgb(77, 65, 65);
}

.bottomInformeItem .InfRight {
    height: 70px;
    width: 150px;
    border-radius: 2px;
    overflow: hidden;
    border: 2px solid rgb(199, 199, 199);
}

.bottomInformeItem .InfRight img {
    height: 100%;
    width: 100%;
    object-fit: cover; 
}

.pagination {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination .MuiPaginationItem-root {
    color: #ec3237; /* Cor vermelha */
    border-color: #ec3237;
}

.pagination .Mui-selected {
    background-color: #ec3237 !important; /* Cor vermelha quando selecionado */
    color: white !important;
}


.monthsList {
    width: 90%;
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10px 20px 10px;
    overflow-y: auto;
}

.monthsList div {
    height: 90%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    min-width: 100%;
}

.monthsList div button {
    border: none;
    padding: 5px 20px 5px 20px;
    border-radius: 5px;
    font-weight: 200;
    font-size: 16px;
    color: gray;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    cursor: pointer;
}

.monthsList div .selected {
    color: rgb(107, 107, 107);
    font-weight: 600;
    background-color: aliceblue;
    border: 1px solid #007bff; /* Adicionando uma borda azul para maior visibilidade */
}


.filters {
    width: 95%;
    display: flex;
    justify-content: start;
    align-items: center;
}

.filters div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 10px;
}

.filters div label {
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    margin-bottom: 5px;
    color: #c72a2f;
}

.filters select{
    cursor: pointer;
    border-radius: 5px;
    width: 150px;
    padding: 7px 15px 7px 15px;
    border: solid 1px rgba(226, 226, 226, 0.863);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    color: gray;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    background-color: white;
}

.filters select:hover{
    outline: none;
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.filters select:focus{
    outline: none;
    border: 1px solid #d4d4d4;
}


@media (max-width:700px) {
    .home {
        width: 100%;
        height: 100%;
    }
    .informeItem {
        width: 95%;
    }
}