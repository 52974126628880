.all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    gap: 5px;
}

.home {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-y: auto;
    padding-top: 20px;
}

/*  */
.topAreaInfo {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 20px;
}

.topAreaInfo .start {
    height: 100%;
}

.topAreaInfo .start h1 {
    font-size: 18px;
    margin: 0px;
    padding: 0px;
}

.topAreaInfo .start p {
    padding-right: 20px;
    font-size: 11px;
    margin: 0px;
    padding: 0px;
    color: gray;
}

.topAreaInfo .end {
    height: 100%;
    width: 500px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
}

.topAreaInfo .end a {
    border-radius: 4px;
    color: white;
    font-size: 11px;
    font-weight: 700;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: #ec3237;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition: 1s;
}

.topAreaInfo .end a {
    text-decoration: none;
    background-color: #c4292e;
}


.contentAtividades {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: auto;
    border-top: 1px solid rgb(206, 206, 206);
}

.elementAtividadeLeft {
    width: 90%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 10px;
    gap: 20px;
    padding-left: 20px;
}

.elementAtividadeLeft div{
    
}

.elementAtividadeLeft div p{
    padding-left: 33%;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    margin-bottom: 5px;
    color: #c72a2f;
}

.elementAtividadeLeft select {
    border-radius: 5px;
    width: 150px;
    padding: 7px 15px 7px 15px;
    border: solid 1px rgba(226, 226, 226, 0.863);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    color: gray;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
}

.elementAtividadeLeft select:focus {
    outline: none;
    /* Garante que não haja borda ao focar */
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    /* Mantém a sombra ao focar */
}

.elementAtividadeLeft .seriesList {
    width: 70%;
    height: 90%;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 9px;
    border-radius: 5px;
    overflow-y: auto;
}

/* Estilizando a barra de rolagem (scrollbar) */
.elementAtividadeLeft .seriesList::-webkit-scrollbar,
.elementAtividadeLeft .seriesList::-webkit-scrollbar {
    width: 7px;
    /* Largura da barra de rolagem vertical */
}

/* Fundo da barra de rolagem */
.elementAtividadeLeft .seriesList::-webkit-scrollbar-track,
.elementAtividadeLeft .seriesList::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Cor de fundo da barra de rolagem */
    border-radius: 10px;
}

/* Polegar da barra de rolagem */
.elementAtividadeLeft .seriesList::-webkit-scrollbar-thumb,
.elementAtividadeLeft .seriesList::-webkit-scrollbar-thumb {
    background: #b3b3b3;
    /* Cor do polegar da barra de rolagem */
    border-radius: 10px;
}

/* Polegar da barra de rolagem ao passar o mouse */
.elementAtividadeLeft .seriesList::-webkit-scrollbar-thumb:hover,
.elementAtividadeLeft .seriesList::-webkit-scrollbar-thumb:hover {
    background: #adadad;
    /* Cor do polegar da barra de rolagem ao passar o mouse */
}

.elementAtividadeLeft .seriesList button {
    cursor: pointer;
    border: none;
    min-width: 50px;
    min-height: 50px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border-radius: 5px;
    background-color: #ec32385e;
    color: rgba(124, 0, 0, 0.527);
    font-weight: 600;
}

.elementAtividadeLeft .seriesList button.selected {
    background-color: #ec3237;
    color: white;
}

.elementAtividadeRight {
    order: 2;
    width: 90%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    border: solid 1px rgba(233, 233, 233, 0.753);
    border-radius: 5px;
}

.elementAtividadeRight .all {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-y: auto;
    gap: 12px;
    padding-top: 20px;
}

.elementAtividadeRight .all .item {
    width: 98%;
    height: 60px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 11px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 0.4px solid rgb(230, 230, 230);
}

.elementAtividadeRight .all .item .sec1 {
    height: 100%;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: #ffde59;
}

.elementAtividadeRight .all .item .sec2 {
    width: 50%;
    height: 100%;
    padding-left: 10px;
    padding-top: 5px;
}

.elementAtividadeRight .all .item .sec2 .tit {
    font: bold 13pt arial;
    font-weight: 600;
    color: #c4292e;
}

.elementAtividadeRight .all .item .sec2 .mat {
    color: rgb(194, 194, 194);
    font: bold 10pt arial;
}

.elementAtividadeRight .all .item .sec3 {
    width: 50%;
    height: 100%;
    padding-right: 20px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    padding-right: 30px;
    color: rgb(94, 94, 94);
}

.elementAtividadeRight .all .item .sec3 .edit {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border: solid 1px rgb(240, 240, 240);
    transition: 1s;
}

.elementAtividadeRight .all .item .sec3 .edit:hover {
    background-color: rgba(128, 128, 128, 0.329);
}

.elementAtividadeRight .all .item .sec3 .delete {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border: solid 1px rgb(240, 240, 240);
    transition: 1s;
}

.elementAtividadeRight .all .item .sec3 .delete:hover {
    background-color: rgba(128, 128, 128, 0.329);
}


.pagination {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination .MuiPaginationItem-root {
    color: #ec3237; /* Cor vermelha */
    border-color: #ec3237;
}

.pagination .Mui-selected {
    background-color: #ec3237 !important; /* Cor vermelha quando selecionado */
    color: white !important;
}

@media (max-width:700px) {
    .home {
        width: 100%;
        height: 100%;
    }
    .topAreaInfo {
        padding-bottom: 50px;
    }

    .elementAtividadeRight .all .item .sec2 {
        width: 50%;
        height: 100%;
        padding-left: 10px;
        padding-top: 20px;
    }

    .elementAtividadeRight .all .item .sec2 .tit {
        font: bold 11pt arial;
        font-weight: 600;
        color: #c4292e;
    }
    
    .elementAtividadeRight .all .item .sec2 .mat {
        color: rgb(194, 194, 194);
        font: bold 8pt arial;
    }

    .elementAtividadeRight .all .item .sec1 {
        height: 100%;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        color: #ffde59;
    }

    .elementAtividadeRight .all .item {
        width: 100%;
        height: 60px;
        border-radius: 5px;
        -webkit-box-shadow: 0px 0px 11px -10px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
        border: 0.4px solid rgb(230, 230, 230);
    }
}


@media (max-width:600px) {
    .topAreaInfo {
        flex-direction: column;
        align-items: start;
    }

    .topAreaInfo .end {
        justify-content: start;
        width: 95%;
        padding-top: 10px;
    }
    .elementAtividadeRight .all .item .sec2 .tit {
        font: bold 10pt arial;
        font-weight: 600;
        color: #c4292e;
    }
    
    .elementAtividadeRight .all .item .sec2 .mat {
        color: rgb(194, 194, 194);
        font: bold 7pt arial;
    }
}