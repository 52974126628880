.form {
    width: 100%;
    height: 100%;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.form .layout {
    width: 90%;
    height: 300px;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.form .layout p {
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    color: gray;
    font-weight: 600;
}

.form .layout input {
    width: 90%;
    margin-top: 5px;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    transition: border-bottom-color 0.3s;
    font-size: 16px;
}

.form .layout input:focus {
    outline: none;
    border-bottom-color: #ec3237;
    /* Cor quando em foco */
}

.form .layout select {
    width: 100%;
    margin-top: 5px;
    padding: 10px;
    font-size: 16px;
    border-radius: 3px;
    border: 1px solid #ccc;
    transition: 1s;
    color: gray;
}

.form .layout select:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

.form .layout textarea {
    width: 90%;
    margin-top: 5px;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    transition: border-bottom-color 0.3s;
    font-size: 16px;
    resize: none;
}

.form .layout textarea:focus {
    outline: none;
    border-bottom-color: #ec3237;
    /* Cor quando em foco */
}

.form .layout .date {
    width: 50%;
    color: gray;
    cursor: pointer;
}

.form .layout2 {
    width: 97%;
    padding: 20px 0px 20px 10px;
    display: flex;
    gap: 5px;
}

.form .layout2 button {
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: end;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    border-radius: 5px;
    padding: 8px 15px 8px 15px;
    cursor: pointer;
    transition: 1s;
    background-color: #ec3237;
    color: white;
}

.form .layout2 button:hover {
    background-color: #c22a2f;
}

.checkboxGroup {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 10px;
    /* Espaçamento entre os itens */
    padding: 10px;
    /* Espaçamento interno */
}

.checkboxLabel {
    display: flex;
    align-items: center;
    /* Alinha verticalmente no centro */
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.checkboxLabel input[type="radio"] {
    margin-right: 10px;
    /* Espaçamento entre o checkbox e o texto */
    appearance: none;
    /* Remove a aparência padrão do rádio */
    -webkit-appearance: none;
    /* Remove a aparência padrão do rádio no WebKit */
    -moz-appearance: none;
    /* Remove a aparência padrão do rádio no Firefox */
    width: 20px;
    /* Largura do rádio */
    height: 20px;
    /* Altura do rádio */
    border: 2px solid #ccc;
    /* Borda do rádio */
    border-radius: 50%;
    /* Cantos arredondados */
    cursor: pointer;
    /* Cursor de ponteiro */
    position: relative;
    /* Necessário para posicionar o pseudo-elemento */

    /* Remove a cor de fundo padrão do rádio */
    background-color: transparent;
}

.checkboxLabel input[type="radio"]::after {
    content: "";
    position: absolute;
    /* Posiciona o pseudo-elemento dentro do rádio */
    top: 50%;
    /* Alinha verticalmente no centro */
    left: 50%;
    /* Alinha horizontalmente no centro */
    width: 12px;
    /* Largura da bolinha interna */
    height: 12px;
    /* Altura da bolinha interna */
    border-radius: 50%;
    /* Cantos arredondados */
    background-color: #db3434;
    /* Cor da bolinha interna */
    transform: translate(-50%, -50%) scale(0);
    /* Centraliza e escala para 0 quando não selecionado */
    transition: transform 0.2s ease-in-out;
    /* Transição suave */
}

.checkboxLabel input[type="radio"]:checked::after {
    transform: translate(-50%, -50%) scale(1);
    /* Escala para 1 quando selecionado */
}

.checkboxLabel input[type="radio"]:checked {
    border-color: #db3434;
    /* Cor da borda quando selecionado */
}
/* Seus estilos existentes permanecem inalterados */

/* Estilos para o botão "Adicionar Turma" */
.addButton {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #28a745; /* Cor verde para adicionar */
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    margin-top: 10px;
}

.addButton:hover {
    background-color: #218838;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.addButton:active {
    background-color: #1e7e34;
    box-shadow: none;
}

.addButton .icon {
    margin-right: 8px;
    font-size: 20px;
}

.addButton:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.4);
}

/* Estilos para o botão "Remover Turma" */
.removeButton {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #dc3545; /* Cor vermelha para remover */
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    margin-top: 10px;
}

.removeButton:hover {
    background-color: #c82333;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.removeButton:active {
    background-color: #bd2130;
    box-shadow: none;
}

.removeButton .icon {
    margin-right: 8px;
    font-size: 20px;
}

.removeButton:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.4);
}

/* Estilos para o botão "Atualizar Usuário" */
.saveButton {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #007bff; /* Cor azul para salvar */
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.saveButton:hover {
    background-color: #0069d9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.saveButton:active {
    background-color: #005cbf;
    box-shadow: none;
}

.saveButton .icon {
    margin-right: 8px;
    font-size: 20px;
}

.saveButton:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.4);
}

/* Estilos para o container de cada turma */
.turmaContainer {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
}

/* Estilos para o título da turma */
.turmaTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Estilos para os campos dentro da turma */
.turmaFields {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}

/* Ajuste dos selects */
.turmaFields select {
    flex: 1;
    padding: 8px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

/* Responsividade */
@media (max-width: 600px) {
    .turmaFields {
        flex-direction: column;
    }

    .addButton, .removeButton, .saveButton {
        width: 100%;
        justify-content: center;
    }
}
