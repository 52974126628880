.container {
    padding: 15px;
    max-width: 500px;
    margin: auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title {
    text-align: center;
    color: #EC3237;
    margin-bottom: 15px;
}

.form {
    display: flex;
    flex-direction: column;
}

.label {
    margin-top: 10px;
    font-weight: bold;
}

.input, 
.textarea, 
.select {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button {
    margin-top: 15px;
    padding: 10px;
    background: #EC3237;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}

.button:hover {
    background: #d92c2f;
}
