.form {
    width: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
}

.form p {
    font-size: 16px;
    color: gray;
    font-weight: 600;
}



.controlButton {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
    gap: 10px;
    background-color: transparent;
}

.controlButton button {
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border: 1px solid rgb(223, 223, 223);
    background-color: transparent;
    color: gray;
    font: 14px solid gray;
    font-weight: 15px;
    font-family: "Quicksand", sans-serif;
    border-radius: 5px;
    display: flex;
    justify-content: certer;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    padding: 5px 14px 5px 14px;
}

.controlButton .submitButton {
    background-color: #c22a2f;
    color: white;
    transition: 1s;
}

.controlButton .submitButton:hover {
    background-color: #c22a2fbb;
}

/* INFORMAÇÔES BASICAS */
.BasicInfoForm {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;
}

.BasicInfoForm h2 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 8px;
    padding: 5px 10px;
    font-weight: bold;
    background-color: #f5f5f5;
    border-left: 4px solid #adadad;
    border-radius: 4px;
}

.BasicInfoForm input {
    width: 90%;
    margin-top: 5px;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    transition: border-bottom-color 0.3s;
    font-size: 16px;
}

.BasicInfoForm input:focus {
    outline: none;
    border-bottom-color: #ec3237;
    /* Cor quando em foco */
}

.BasicInfoForm select {
    width: 93%;
    margin-top: 5px;
    padding: 10px;
    font-size: 16px;
    border-radius: 3px;
    border: 1px solid #ccc;
    transition: 1s;
    color: gray;
}

.BasicInfoForm select:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

/* QUESTÕES */
.questoesAll {
    width: 100%;
    max-height: 400px;
    min-height: 400px;
    overflow-y: auto;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 20px;
}

.questao {
    width: 90%;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-bottom: 20px;
}

.questao .TopArea {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.questao .TopArea button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #7a0509;
    background-color: #ff8387ee;
    font-size: 15px;
    transition: 1s;
    cursor: pointer;
}

.questao .TopArea button:hover {
    color: #7a0509b7;
    background-color: #ff83879d;
}

.questionelement {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.questionelement label {
    color: #7a0509b7;
    font-weight: 800;
    font-size: 15px;
}

.questionelement input {
    border: 1px solid rgb(206, 206, 206);
    color: gray;
}

.questionelement input:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

.respostas {
    width: 95%;
    padding-top: 10px;
    padding-left: 10px;
}

.respostas label {
    color: #7a0509b7;
    font-weight: 800;
    font-size: 13px;
}

.respostas input {
    width: 50%;
    border: 1px solid rgb(206, 206, 206);
    color: gray;
}

.respostas input:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

.itemCorreto {
    width: 95%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}

.itemCorreto label {
    color: #7a0509b7;
    font-weight: 800;
    font-size: 15px;
}

.itemCorreto select {
    border: 1px solid rgb(206, 206, 206);
    color: gray;
}

.itemCorreto select:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

.reactSelect {
    width: 80%;
}

.editorWrapper {
    width: 100%;
    max-width: 100%;
    /* Garante que não ultrapasse 100% da largura do contêiner pai */
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background: #fff;
    box-sizing: border-box;
    /* Inclui padding e borda na largura total */
    overflow: hidden;
    /* Impede que o conteúdo transborde */
}

.editor {
    min-height: 200px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #fff;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 10px;
}

.toolbar {
    border: 1px solid #ddd;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    background: #f5f5f5;
}

.BasicInfoForm .error {
    color: rgb(224, 0, 0);
    font-weight: 500;
    font-size: 14px;
    margin-top: 0px;
}

.materiaItemHeader {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding-bottom: 5px;
}

.materiaItemHeader h2 {
    margin-bottom: 0px;
}

.materiaItemHeader .removeButton {
    border: solid 1px rgba(255, 0, 0, 0.288);
    background-color: #f14248;
    color: white;
    border-radius: 5px;
    padding: 5px 6px 5px 6px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 1s;
}

.materiaItemHeader .removeButton:hover {
    background-color: #aa1a1e;
}



@media (max-width:700px) {
    .form {
        width: 90%;
        padding-bottom: 30px;
    }

    .reactSelect {
        width: 90%;
    }
}