.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form {
    width: 100%;
}

.inputGroup {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.inputGroup label {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin-bottom: 5px;
}

.inputGroup input {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s;
}

.inputGroup input:focus {
    border-color: #007bff;
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
}

.saveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.saveButton:hover {
    background-color: #0056b3;
}

.saveButton svg {
    margin-right: 8px;
}

.message {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
}

.message.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}
