.all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.home {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-y: auto;
}

.returnPainelArrow {
    padding-top: 20px;
    width: 96%;
}

.returnPainelArrow a {
    width: 90px;
    display: flex;
    justify-content: start;
    align-items: center;
    text-decoration: none;
    color: #ec3237;
    font-size: 12px;
    transition: 0.5s;
}

.returnPainelArrow a:hover {
    color: #c4292e;
}

.topAreaInfo {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 0px;
}

.topAreaInfo .start {
    height: 100%;
}

.topAreaInfo .start h1 {
    font-size: 18px;
    margin: 0px;
    padding: 0px;
}

.topAreaInfo .start p {
    padding-right: 20px;
    font-size: 11px;
    margin: 0px;
    padding: 0px;
    color: gray;
}

.topAreaInfo .end button {
    border-radius: 4px;
    color: white;
    font-size: 11px;
    font-weight: 700;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: #ec3237;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition: 1s;
    border: none;
}

.actionsList {
    width: 90%;
    height: 40px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
}

.actionsList button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #7a0509;
    background-color: #ff8387ee;
    font-size: 15px;
    transition: 1s;
    cursor: pointer;
}

.actionsList button:hover {
    color: #7a0509b7;
    background-color: #ff83879d;
}

.actionsList .updateButton {
    background-color: #fff383ee;
    color: #665d07ee;
}

.actionsList .updateButton:hover {
    background-color: #dacf70ee;
}

.pdfDownload {
    background-color: #d83434ee;
    color: #fd8585ee;
}

.pdfDownload:hover {
    background-color: #dacf70ee;
}

.bottomAreaInfo {
    width: 100%;
}

.bottomAreaInfo .responsesList {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 30px;
}

.bottomAreaInfo .responsesList .responseItem {
    width: 95%;
    border-radius: 5px;
    border: 1px solid rgb(231, 229, 229);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border-bottom: 7px solid rgb(219, 36, 36);
    padding: 8px;
}

.responseActions {
    padding: 0px 10px 20px 20px;
    display: flex;
    justify-content: space-between;
}

.responseActions .left {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    width: 80%;
}

.responseActions .right {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
}

.responseActions .right button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #7a0509;
    background-color: #ff8387ee;
    font-size: 15px;
    transition: 1s;
    cursor: pointer;
}

.responseActions .right button:hover {
    color: #7a0509b7;
    background-color: #ff83879d;
}

.responseActions .right .updateButton {
    background-color: #fff383ee;
    color: #665d07ee;
}

.responseActions .right .updateButton:hover {
    background-color: #dacf70ee;
}

.responseActions .right .archiveButton {
    background-color: #a7a7a7ee;
    color: #585858ee;
}

.responseActions .right .archiveButton:hover {
    background-color: #a7a7a7ee;
    color: #585858ee;
    opacity: 0.7;
}

.answerItem {
    direction: flex;
    flex-direction: column;
}

.answerItem .question {
    color: rgb(87, 87, 87);
    font-weight: 600;
    font-size: 19px;
}

.answerItem .answer {
    color: rgb(110, 110, 110);
    font-weight: 400;
    font-size: 17px;
}

/* Not Found */

.noResponses {
    max-height: 50%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
}

.noResponses {
    font-weight: 600;
    color: gray;
}

.noResponses img {
    width: 200px;
    height: 200px;
}

.filterArea {
    width: 96%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0px 5px 0px 5px;
    width: 95%;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 60px;
    padding-left: 20px;
}

.filterArea .group {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.filterArea .group label {
    padding: 0px;
    margin: 0px;
    color: gray;
    font-weight: 600;
}

.filterArea .group select {
    border: 1px solid rgb(207, 207, 207);
    background-color: white;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    color: gray;
    cursor: pointer;
    transition: 1s;
}

.filterArea .group .inputSearch {
    border: 1px solid rgb(207, 207, 207);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: white;
    border-radius: 5px;
    padding: 0px 10px 0px 10px;
    color: gray;
    cursor: pointer;
    transition: 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 30px;
}
.filterArea .group .inputSearch input{
    height: 95%;
    border: none;
}

.filterArea .group .inputSearch input:focus{
    outline: none;
    border:none;
}

.filterArea .group .revert {
    height: 50px;
    display: flex;
    align-items: end;
}

.filterArea .group button {
    width: 30.5px;
    height: 30.5px;
    border-radius: 5px;
    border: 1px solid rgb(202, 202, 202);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(59, 59, 59);
    cursor: pointer;
}

.filterArea .group select:focus {
    outline: none;
    border: 1px solid rgb(177, 31, 31);
}

/* show Data */
.initialArea {
    width: 100%;
    min-height: 150px;
    gap: 10px;
    display: flex;
    align-items: center;
    overflow-x: auto;
}

.initialArea .content {
    min-width: 750px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.initialArea .content div{
    width: 200px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    border: 1px solid rgb(221, 221, 221);
    cursor: pointer;
}

.initialArea .content div p{
    padding: 0px;
    margin: 0px;
    color: gray;
    font-size: 13px;
    font-weight: 600;
    padding-top: 10px;
}

.initialArea .content div h2{
    padding: 0px;
    margin: 0px;
    color: rgb(177, 31, 31);
}

.toggleButton {
    background-color: transparent;
    color: #CE474C;
    border: none;
    cursor: pointer;
    font-size: 14px;
    padding: 5px;
    text-decoration: underline;
    margin-top: 5px;
}

.toggleButton:hover {
    color: #0056b3;
}

.responseArchived {
    width: 95%;
    border-radius: 5px;
    border: 1px solid rgb(231, 229, 229);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border-bottom: 7px solid rgb(133, 133, 133);
    padding: 8px;
}


@media (max-width:700px) {
    .initialArea .content {
        min-width: 600px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .initialArea .content div{
        width: 180px;
        height: 80px;
    }
}
/*  */
/* FichaEditReceivedPage.module.css */

/* Estilo do overlay do popup */
/* FichaReceivedPage.module.css */

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Certifique-se de que o modal fique sobre outros elementos */
}

.popupContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-width: 600px;
    width: 100%;
}


.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Garante que o popup está acima de outros elementos */
}

.popupContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    height: 80%;
    width: 80%;
    max-width: 900px;
    position: relative;
}

.closeButton {
    position: absolute;
    top: 0px;
    right: 0px;
    color: rgb(114, 114, 114);
    background-color: transparent;
    border: none;
    padding: 20px 20px;
    cursor: pointer;
    font-size: 25px;
    transition: 1s;
}

.closeButton:hover {
    color: rgb(196, 41, 41);
}


/* Estilo para o conteúdo dentro do popup */
.editResponse {
    margin-top: 20px;
    /* Espaço acima do conteúdo do popup */
}


@media (max-width: 500px) {
    .formItem .top .left {
        width: 60%;
    }
}

@media (max-width:700px) {
    .home {
        width: 100%;
        height: 100%;
    }

    .modalOverlay {}

    .modalContent {
        border-radius: 0px;
        width: 100%;
        height: 100%;
        padding-top: 100px;
    }

    .modalContent form {
        max-height: 550px;
    }

    .bottomAreaInfo .responsesList .responseItem {
        width: 90%;
    }

    .popupContent {
        background: white;
        padding: 20px;
        border-radius: 0px;
        height: 96%;
        width: 100%;
        max-width: 100%;
    }
}