.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}

.materiaContainer {
    background: #ffffff;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 15px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.formGroup label {
    font-weight: bold;
    margin-bottom: 5px;
}

.formGroup input[type="text"],
.formGroup select,
.colorInput {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #f7f7f7;
}

.colorInput {
    cursor: pointer;
    width: 50px;
    height: 40px;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.submitButton,
.secondaryButton,
.cancelButton {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s ease;
}

.submitButton {
    background: #4CAF50;
    color: #fff;
}

.secondaryButton {
    background: #008CBA;
    color: #fff;
}

.cancelButton {
    background: #f44336;
    color: #fff;
}

.submitButton:hover,
.secondaryButton:hover,
.cancelButton:hover {
    opacity: 0.85;
}

/* Estilo para o botão de remover matéria */
.removeButton {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.removeButton:hover {
    background-color: #c0392b;
}


.secondaryButton{
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s ease;
}


@media (max-width:800px) {
    .form {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}
