.all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.home {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-y: auto;
}

.returnPainelArrow {
    padding-top: 20px;
    width: 96%;
}

.returnPainelArrow a {
    width: 70px;
    display: flex;
    justify-content: start;
    align-items: center;
    text-decoration: none;
    color: #ec3237;
    font-size: 12px;
    transition: 0.5s;
}

.returnPainelArrow a:hover {
    color: #c4292e;
}

.topAreaInfo {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.topAreaInfo .start {
    height: 100%;
}

.topAreaInfo .start h1 {
    font-size: 18px;
    margin: 0px;
    padding: 0px;
}

.topAreaInfo .start p {
    padding-right: 20px;
    font-size: 11px;
    margin: 0px;
    padding: 0px;
    color: gray;
}


.topAreaInfo .end {
    height: 100%;
    width: 500px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
}

.topAreaInfo .end a {
    border-radius: 4px;
    color: white;
    color: #ffe7e8;
    font-size: 11px;
    font-weight: 700;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: #ec3237;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition: 1s;
}

.topAreaInfo .end a {
    text-decoration: none;
    background-color: #c4292e;
}

.listCardapio {
    width: 100%;
    border-top: 1px solid gray;
    overflow-x: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.monthsList {
    width: 90%;
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10px 20px 10px;
    overflow-y: auto;
}

.monthsList div {
    height: 90%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    min-width: 100%;
}

.monthsList div button {
    border: none;
    padding: 5px 20px 5px 20px;
    border-radius: 5px;
    font-weight: 200;
    font-size: 16px;
    color: gray;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    cursor: pointer;
}

.monthsList div .selected {
    color: rgb(107, 107, 107);
    font-weight: 600;
    background-color: aliceblue;
    border: 1px solid #007bff;
    /* Adicionando uma borda azul para maior visibilidade */
}

.table_all {
    width: 87%;
    background-color: #FFFFFF;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border: 1px solid rgb(197, 197, 197);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    font-family: "Quicksand", sans-serif;
    padding: 20px;
}

.action_buttons {
    display: flex;
    justify-content: end;
    padding-right: 40px;
    align-items: center;
    height: 10%;
    width: 97%;
    gap: 10px;
}

.action_buttons button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #7a0509;
    background-color: #ff8387ee;
    font-size: 15px;
    transition: 1s;
    cursor: pointer;
}

.action_buttons button:hover {
    color: #7a0509b7;
    background-color: #ff83879d;
}

.action_buttons .updateButton {
    background-color: #fff383ee;
    color: #665d07ee;
}


.action_buttons .updateButton:hover {
    background-color: #dacf70ee;
}

.action_buttons .downloadPdfButton {
    background: #CC0E10;
    color: white;
}

.action_buttons .downloadPdfButton:hover {
    background: rgb(255, 198, 198);
}

.action_buttons .downloadExcelButton {
    background-color: #175935;
    color:  #31BE7D;
}
.action_buttons .downloadExcelButton:hover {
    background-color: #175935d5;
    color:  #31BE7D;
}

.tableOverflow {
    max-width: 90%;
    overflow-x: auto; /* Permite rolagem horizontal */
    background-color: #FFFFFF;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(197, 197, 197);
    min-height: fit-content;
}

.table {
    width: 100%;
    border-collapse: collapse; /* Remove espaços entre células */
}

.table th, .table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd; /* Linha de divisão discreta */
}

.table th {
    background-color: #EC3237; /* Cabeçalho com cor mais forte */
    color: white; /* Texto branco no cabeçalho */
    text-transform: uppercase; /* Texto em maiúsculas para o cabeçalho */
    font-weight: bold;
    min-width: 200px;
}

.table tbody tr:nth-child(even) {
    background-color: #f2f2f2; /* Cor alternada para linhas pares */
}

.table tbody tr:hover {
    background-color: #e9ecef; /* Cor de destaque ao passar o mouse */
    cursor: pointer;
}

.table .refeicao {
    font-weight: bold; /* Destaca a coluna de refeições */
}

.itemList li {
    margin-left: 10px;
    padding: 8px 12px; /* Espaçamento interno */
    background-color: #f1f1f1; /* Cor de fundo suave */
    border-radius: 6px; /* Bordas arredondadas */
    list-style-type: none; /* Remove o marcador padrão */
    position: relative; /* Para posicionar o novo marcador */
    font-size: 0.9rem; /* Ajusta o tamanho da fonte */
    color: #333; /* Cor do texto */
    margin-bottom: 6px; /* Espaçamento entre os itens */
    font-weight: 800;
}

.itemList li::before {
    content: '•';
    position: absolute;
    left: -15px;
    color:#007bff; /* Usando a cor de categoria dinâmica */
    font-size: 1.2rem;
    top: 4px;
}

.pagination {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

/* Filters */

.filters {
    width: 89.5%;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 10px;
}

.filters div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filters div label {
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    margin-bottom: 5px;
    color: #c72a2f;
}

.filters select{
    cursor: pointer;
    border-radius: 5px;
    width: 150px;
    padding: 7px 15px 7px 15px;
    border: solid 1px rgba(226, 226, 226, 0.863);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    color: gray;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    background-color: white;
}

.filters select:hover{
    outline: none;
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.filters select:focus{
    outline: none;
    border: 1px solid #d4d4d4;
}

/* CardapioComponent.module.css */

.notFound {
    text-align: center;
    margin-top: 50px;
}

.notFound img {
    max-width: 200px;
    width: 100%;
    height: auto;
}

.observacao {
    width: 86.3%;
    margin-top: 10px;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
}

.observacao h3 {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.observacao p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #666;
}

@media (max-width:700px) {
    .home {
        width: 100%;
        height: 100%;
    }

    .table_all {
        min-width: 70%;
        border-radius: 10px;
        align-items: start;
    }

    .table_all h2{
        font-size: 22px;
    }

    .table {
        min-width: 800px;
    }

    .listCardapio {
        min-height: 70%;
        max-height: 100%;
    }

    .observacao {
        width: 93%;
    }
}

@media (max-width:450px) {
    .topAreaInfo {
        flex-direction: column;
        align-items: start;
        padding-bottom: 50px;
    }

    .topAreaInfo .end {
        width: 100%;
        padding-right: 0px;
        justify-content: start;
        padding-top: 10px;
    }
}