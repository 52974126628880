.all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.home {
    width: 100%;
    height: 100%;
}

.Area {
    width: 100%;
    height: 100%;
    display: flex;
}

.Area .start {
    width: 250px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-y: auto;
    border-right: 1px solid rgb(197, 197, 197);
}

.Area .start .searchUser {
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: center;
}

.Area .start .searchUser div {
    width: 95%;
    height: 40px;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 10px;
    border: 1px solid rgb(212, 212, 212);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    gap: 5px;
    padding-left: 10px;
    color: #ec3237;
}

.Area .start .searchUser div input {
    color: gray;
    height: 95%;
    border: none;
}

.Area .start .searchUser div input:focus {
    outline: none;
}

.Area .start .filterUser {
    width: 95%;
    display: flex;
    justify-content: start;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 5px;
}

.Area .start .filterUser div {
    background-color: rgb(238, 238, 238);
    border-radius: 10px;
    padding: 4px 8px 4px 8px;
    cursor: pointer;
    transition: 1s;
    font-weight: 700;
    font-size: 13px;
    color: rgb(77, 77, 77);
}

.Area .end {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    background-color: white;
}

.Area .end .leaveChat {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
}

.Area .end .leaveChat button {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: 1s;
    color: #ec3237;
    font-size: 23px;
}

.Area .end .leaveChat button:hover {
    border: none;
    background-color: rgba(255, 255, 255, 0.541);
    cursor: pointer;
}

.Area .end .messages {
    width: 100%;
    height: 87%;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    margin-bottom: 10px;
}

.Area .end .messages .message {
    width: 80%;
    height: 100%;
    padding-bottom: 10px;
}

/* ChatPage.module.css */
.messChat {
    max-width: 60%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 10px;
    position: relative;
    word-wrap: break-word;
    font-size: 14px;
}

.sent {
    background-color: #dcf8c6;
    align-self: flex-end;
    border-bottom-right-radius: 0;
}

.received {
    background-color: #ffffff;
    align-self: flex-start;
    border-bottom-left-radius: 0;
    border: 1px solid #ddd;
    font-weight: 500;
}

/* Adicione outros estilos conforme necessário */


.Area .end .chat {
    width: 100%;
    height: 12%;
    display: flex;
    justify-content: center;
}

.Area .end .chat div {
    width: 90%;
    height: 50px;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border: 1px solid rgb(228, 228, 228);
    gap: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.Area .end .chat div input {
    border: none;
    width: 50%;
    height: 90%;
    overflow-y: auto;
}

.Area .end .chat div input:focus {
    outline: none;
    border: none;
}

.Area .end .chat div button {
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 8.5px;
    background-color: #ec3237;
    color: yellow;
}

.Perfischats {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 10px;
    gap: 10px;
    cursor: pointer;
    border-bottom: solid 1px rgb(197, 197, 197);
}

.Perfischats:hover {
    background-color: rgb(207, 207, 207);
}

.Perfischats img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: solid 1px rgb(219, 219, 219);
}

.unreadBadge {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    color: white;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 600;
    margin-left: 10px;
    width: 18px;
    height: 18px;
}

@media (max-width:1000px) {
    .Area .start {
        width: 40%;
    }
}

@media (max-width:700px) {

    .Area .start .searchUser div {
        height: 45px;
    }

    .Area .start {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        overflow-y: auto;
    }

    .Area .end {
        display: none;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 1002;
        top: 0;
        right: 0;
    }

    .Area .end .leaveChat {
        height: 10%;
    }

    .Area .end .messages {
        height: 70%;
    }

    .Area .end .chat {
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: end;
        padding-bottom: 20px;
    }

    .Area .end .chat div {
        width: 90%;
    }
}