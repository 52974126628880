.boletimTabela {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.boletimTabela thead tr {
    background-color: #ec3237; /* Verde suave */
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}

.boletimTabela th,
.boletimTabela td {
    padding: 12px 15px;
    border: 1px solid #ddd;
}

.boletimTabela tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Cinza claro */
}

.boletimTabela tbody tr:hover {
    background-color: #f1f1f1; /* Destaque ao passar o mouse */
}

.boletimTabela input {
    width: 60px;
    padding: 6px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
}

.boletimTabela td input:focus {
    border-color: #b1272b; /* Verde ao focar */
    outline: none;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5); /* Efeito de foco */
}

.status-aprovado {
    color: #ec3237; /* Verde */
    font-weight: bold;
}

.status-recuperacao {
    color: #ff9800; /* Laranja */
    font-weight: bold;
}

.status-reprovado {
    color: #f44336; /* Vermelho */
    font-weight: bold;
}

.boletimTitulo {
    color: #333;
    text-transform: uppercase;
    font-size: 24px;
    margin-bottom: 10px;
    border-bottom: 2px solid #ec3237; /* Linha de destaque */
    display: inline-block;
    font-weight: 700;
}

@media (max-width:700px) {
    .boletim {
        overflow-x: auto;
    }
}