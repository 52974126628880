@keyframes slideDown {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(122, 122, 122, 0.349);
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    background-color: rgb(255, 255, 255);
    width: 70%;
    height: 70%;
    padding: 20px;
    border-radius: 10px;
    display:flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    animation: slideDown 0.5s ease-out;
}

.animate {
    animation: slideDown 0.5s ease-out;
}

.closeButton {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: right;
}

.closeButton button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 20px;
    color: gray;
}

.form {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.send {
    width: 60%;
    height: 100%;
}

.send p {
    font-weight: 600;
    color: gray;
    font-size: 16px;
}

.email {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.email label {
    color: black;
    font-weight: 600;
    font-size: 14px;
}

.email input {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    border: solid 0.5px rgb(211, 211, 211);
    padding: 5px;
}

.email input:focus {
    outline: none;
    border: solid 0.7px rgb(173, 173, 173);
}

.send button {
    width: 80%;
    height: 40px;
    margin-top: 10px;
    border: none;
    color: white;
    font-weight: 700;
    font-size: 13px;
    border-radius: 40px;
}

.buttonEnabled {
    background-color: #ffdd58;
    cursor: pointer;
}

.buttonDisabled {
    background-color: #DDDDDD;
    cursor: not-allowed;
}

.img {
    width: 40%;
}

.img img {
    width: 90%;
    height: 90%;
}

.error p{
    color: red;
    font-size: 11px;
    font-weight: 200;
}
.successMessage {
    width: 80%;
    height: 100%;
}
.successMessage p{
    font-weight: 600;
    color: gray;
    font-size: 16px;
}

.successMessage strong{
    font-weight: 600;
    color: rgb(71, 71, 71);
    font-size: 16px;
}

@media (max-width: 700px) {
    .content {
        width: 100%;
        height: 100%;
    }
    .img {
        display: none;
    }
    .send {
        width: 100%;
        height: 100%;
    }
}
