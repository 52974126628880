.all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.home {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-y: auto;
}

.returnPainelArrow {
    padding-top: 20px;
    width: 96%;
}

.returnPainelArrow a {
    width: 70px;
    display: flex;
    justify-content: start;
    align-items: center;
    text-decoration: none;
    color: #ec3237;
    font-size: 12px;
    transition: 0.5s;
}

.returnPainelArrow a:hover {
    color: #c4292e;
}

.topAreaInfo {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 20px;
}

.topAreaInfo .start {
    height: 100%;
}

.topAreaInfo .start h1 {
    font-size: 18px;
    margin: 0px;
    padding: 0px;
}

.topAreaInfo .start p {
    padding-right: 20px;
    font-size: 11px;
    margin: 0px;
    padding: 0px;
    color: gray;
}

.userList {
    width: 95%;
    height: 95%;
    flex: 4;
}

.userListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #ffde59;
    color: white;
    cursor: pointer;
    margin-right: 5px;
    transition: 1s;
}

.userListEdit:hover{
    background-color: #ffd220;
}

.userPasswordEdit {
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin-right: 5px;
    transition: 1s;
}

.userPasswordEdit:hover{
    background-color: #005abb;
}

.userListDelete{
    color: #ffffff;
    cursor: pointer;
    transition: 1s;
}
.userListDelete:hover{
    color: #ffffff;
}
.actionButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fc9598;
    padding: 10px 20px;
    border-radius: 8px;
    border: 1px solid #EC3237;
    margin-bottom: 15px;
}

.actionsLeft {
    display: flex;
    align-items: center;
    gap: 15px;
}

.actionsLeft span {
    font-size: 14px;
    font-weight: 500;
    color: #c4292e;
}

.userListEdit {
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #ffde59;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.userListEdit:hover {
    background-color: #ffd220;
}

.userListDelete {
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #ec3237;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.userListDelete:hover {
    background-color: #c4292e;
}

.closeButton {
    background: none;
    border: none;
    color: #c4292e;
    cursor: pointer;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
}

.closeButton:hover {
    color: #ec3237;
}

@media (max-width:700px) {
    .home {
        width: 100%;
        height: 100%;
    }

    
    .actionButtons {
        flex-direction: column;
        align-items: flex-start;
    }

    .actionsLeft {
        width: 100%;
        justify-content: space-between;
    }
}