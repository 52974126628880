/* src/pages/AddTenant.module.css */
.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 5px;
  }
  
  .formGroup input,
  .formGroup select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .buttonGroup {
    display: flex;
    justify-content: space-between;
  }
  
  .buttonGroup button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .buttonGroup button[type='submit'] {
    background-color: #4caf50;
    color: white;
  }
  
  .buttonGroup button[type='button'] {
    background-color: #f44336;
    color: white;
  }
  